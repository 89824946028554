import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import _ from 'underscore';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const user = this.authenticationService.userSession;
        if (user) {
            const permissions: string[] = user.permissions;
            let hasPermission = false;
            permissions.some((value : any, index)=>{
                hasPermission = value.access_paths?.some((p: any)=> p == `/${route.url[0].path}`)
                if(hasPermission){
                  return hasPermission;
                }
                return false;
              })
            if (!hasPermission) {
                // not logged in so redirect to login page with the return url
                this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
            }
            return hasPermission;
        }
        return false;
    }
}