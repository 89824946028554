import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { EditableSetting, Setting } from '../models/setting.model';
import { SettingService } from '../services/setting.service';
import { FormsModule } from '@angular/forms';
import { SharePrimeNGModule } from '../share-primeng.module';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CommonModule, FormsModule, SharePrimeNGModule],
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.css',
  providers: [ConfirmationService],
})
export class SettingsComponent {
  settings: Setting[] = [];
  settingsOriginal: EditableSetting[] = [];
  settingCategories: any[] = [
    {
      category: 'SYS',
      description: 'System',
      settings: [],
    },
    {
      category: 'MANAGEMENT',
      description: 'Management',
      settings: [],
    },
  ];

  constructor(private settingService: SettingService, private confirmationService: ConfirmationService) {}

  ngOnInit(): void {
    this.loadSettings();
  }

  loadSettings() {
    this.settingService.findAllSettings().subscribe({
      next: (response) => {
        this.settings = response.sort((a, b) => a.key.localeCompare(b.key));

        this.settingCategories.map((category) => {
          category.settings = this.settings
            .filter((s) => s.category === category.category)
            .map((s) => {
              return {
                ...s,
                isEditMode: false, // Default value when missing from server
              };
            });
        });
      },
      error: (error) => {
        console.error('Error loading settings:', error);
      },
      complete: () => {},
    });
  }

  enableSetting(s: EditableSetting) {
    s.isEditMode = true;
    // Copy original locally
    const index = this.settingsOriginal.findIndex((i) => i.id === s.id);
    if (index < 0) {
      this.settingsOriginal.push({ ...s });
    } else {
      // Add new selected setting
      this.settingsOriginal[index] = { ...this.settingsOriginal[index], ...s };
    }
  }

  saveSetting(s: EditableSetting) {
    s.isEditMode = false;
    const idx = this.settingsOriginal.findIndex((i) => i.id === s.id);
    if (idx !== -1 && s.value !== this.settingsOriginal[idx].value) {
      // Has change?
      this.confirmationService.confirm({
        message: `Are you sure you want to apply new change?`,
        header: 'Change Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          // Update a setting
          s.modified_time = new Date();

          this.settingService.updateSetting(s).subscribe({
            next: () => {},
            error: (error) => {
              console.error('Error updating setting:', error);
            },
            complete: () => {},
          });
        },
        reject: () => {
          s.value = this.settingsOriginal[idx].value;
        },
      });
    }
  }

  cancelEditSetting(s: EditableSetting) {
    s.isEditMode = false;
    const idx = this.settingsOriginal.findIndex((i) => i.id === s.id);
    if (idx !== -1) {
      s.value = this.settingsOriginal[idx].value;
    }
  }
}
