<form [formGroup]="contactForm" class="justify-content-center" style="display: flex" (ngSubmit)="submitDetails()">
    <p-card header="Contact Us" class="justify-content-center" [style]="{ width: '800px' }">
      <div class="grid">
        <div class="col">
          <div class="field">
            <label for="fullName">Full Name</label>
            <input
              type="text"
              class="w-full h-3rem border-round-md border-1 border-primary-400"
              pInputText
              formControlName="fullName"
              placeholder="Type your full name"
            />
            <small *ngIf="fullName.invalid && (fullName.dirty || fullName.touched)" class="block p-error">
              <div *ngIf="fullName.errors?.['required']">Name is required.</div>
              <div *ngIf="fullName.errors?.['pattern']">Enter a valid Name</div>
            </small>
          </div>
          <div class="field">
            <label for="email">Email</label>
            <input
              type="text"
              pInputText
              formControlName="email"
              class="w-full h-3rem border-round-md border-1 border-primary-400"
              name="email"
              placeholder="Type your email"
            />
            <small *ngIf="email.invalid && (email.dirty || email.touched)" class="block p-error">
              <div *ngIf="email.errors?.['required']">Email is required.</div>
              <div *ngIf="email.errors?.['email']">Email should be valid</div>
            </small>
          </div>
          <div class="field">
            <label for="subject">Subject</label>
            <input
              type="text"
              class="w-full h-3rem border-round-md border-1 border-primary-400"
              pInputText
              formControlName="subject"
              placeholder="Type subject"
            />
            <small *ngIf="subject.invalid && (subject.dirty || subject.touched)" class="block p-error">
              <div *ngIf="subject.errors?.['required']">Subject is required.</div>
            </small>
          </div>
          <div class="field">
            <label for="message">Message</label>
            <textarea style="height: 300px;"
              type="text"
              class="w-full border-round-md border-1 border-primary-400"
              pInputText
              formControlName="message"
              placeholder="Write your message"></textarea>
            <small *ngIf="message.invalid && (message.dirty || message.touched)" class="block p-error">
              <div *ngIf="message.errors?.['required']">Message is required.</div>
            </small>
          </div>
        </div>
      </div>
      <div class="grid">
        <div class="col-4"></div>
        <div class="col-4">
          <p-button label="Send" styleClass="w-full btn-create" [style]="{ 'margin-left': '2rem' }" [disabled]="contactForm.invalid" type="submit"></p-button>
        </div>
      </div>
    </p-card>
  </form>
  <br />
  <p-toast position="top-center"></p-toast>
  