import { Component, Input } from '@angular/core';
import { BehaviorSubject, filter, switchMap, tap, timer } from 'rxjs';

@Component({
  selector: 'app-loading-overlay',
  standalone: true,
  imports: [],
  templateUrl: './loading-overlay.component.html',
  styleUrl: './loading-overlay.component.css'
})
export class LoadingOverlayComponent {
  @Input() autoHide: number = 10000;
  @Input()
  loading: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor() {
    this.loading.pipe(
      filter(isLoading => isLoading),
      switchMap(() => timer(this.autoHide)),
      tap(() => this.loading.next(false))
    ).subscribe();
  }
}
