import { Component, effect, OnDestroy, signal, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { filter, map, merge, Observable, of, Subscription, switchMap, tap, } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';

import { SharePrimeNGModule } from '../../../share-primeng.module';
import { ToolbarComponent, ToolbarModel } from '../toolbar/toolbar.component';
import { TYPES } from '../table/table.model';
import { CommonService } from '../../../services/common.service';
import { TimelineComponent } from '../timeline/timeline.component';
import { UtilityService } from '../../../services/utility.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharePrimeNGModule,
    ToolbarComponent,
    TimelineComponent
  ],
  templateUrl: './form.component.html',
  styleUrl: './form.component.css',
  providers: [
    ConfirmationService
  ]
})
export class FormComponent implements OnDestroy {
  timelines: any[] | undefined;
  toolbarForSave: ToolbarModel | undefined;
  sectionConfigs: any;
  listSectionConfig: any;

  listSectionConfigSignal: WritableSignal<any> | undefined;

  TYPES = TYPES;
  formGroup: FormGroup = new FormGroup({}, { updateOn: 'blur' });
  toolbarConfigsForOrderItem: any;

  subscriptions: Subscription = new Subscription();

  checkboxState: boolean[] = [];

  itemsSubscription: Subscription = new Subscription();

  getFormGroupFromFormArray = (key: string, idx: number) => (this.formGroup.get(key) as any)['controls'][idx];
  constructor(private config: DynamicDialogConfig, private ref: DynamicDialogRef, private commonService: CommonService, private confirmationService: ConfirmationService, private utilityService: UtilityService) {
    this.initForm(this.config.data);

    const [cancelButton, saveButton] = this.toolbarForSave!.rightButtons!;
    this.subscriptions.add(saveButton.event.pipe(
      filter(() => this.formGroup.dirty),
      switchMap(() => {
        return this.getConfirmDialog('save');
      }),
      switchMap(() => of(this.formGroup.getRawValue())),
      tap(() => {
        this.commonService.isLoading$.next(true);
      }),
      switchMap(data => saveButton.httpRequest!(data))
    ).subscribe({
      next: (data) => {
        if (!data.error) {
          this.ref.close(true);
        }
        this.commonService.isLoading$.next(false);
      },
      error: (err) => {
        this.commonService.isLoading$.next(false);
        console.error(err);
      }
    }));

    this.subscriptions.add(
      cancelButton.event.subscribe()
    );

    this.formGroup.valueChanges.subscribe(() => {
      saveButton.disabled = !this.formGroup.valid;
    });

  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  readonly initForm = ({ toolbarForSave, sectionConfigs, listSectionConfig, timelines }: any) => {
    this.timelines = timelines;
    this.toolbarForSave = toolbarForSave;
    this.sectionConfigs = sectionConfigs;

    if (listSectionConfig) {
      this.sectionConfigs = [...this.sectionConfigs, listSectionConfig];
      let listSection = listSectionConfig;
      let imageOrder: any[] = [];

      if (listSection.hasImages) {
        const items = listSection[listSection.key].map((item: any) => {
          if (item.images) {
            return item;
          }
          return {
            ...item,
            images: listSection.hasImages.imageOrder.filter((key: string) => item[key]).map((key: string) => ({
              src: item[key],
              byPassCORSrc: `${environment.apiUrl}/order/get-file/${encodeURIComponent(item[key])}`,
              title: key,
            }))
          };
        });
        listSection = {
          ...listSection,
          [listSection.key]: items.length ? items : [{ images: [] }],
        };

        imageOrder = listSection.hasImages.imageOrder;
      }

      this.listSectionConfigSignal = listSectionConfig.listSectionConfigSignal = signal(listSection);
      this.checkboxState = Array(listSection[listSection.key].length).fill(false);

      if (listSectionConfig.common?.configurations?.leftButtons) {
        const [addButton, deleteButton] = listSectionConfig.common.configurations.leftButtons;
        this.subscriptions.add(addButton.event.subscribe());

        this.subscriptions.add(deleteButton.event.subscribe(() => {
          const listSection = this.listSectionConfigSignal!()
          const formArray: FormArray = this.formGroup.get(listSection.key!) as FormArray;

          this.checkboxState.map((isChecked, index) => isChecked && index)
            .filter((idxStr: any) => !isNaN(+`${idxStr}`))
            .sort((a: any, b: any) => b - a)
            .forEach(index => {
              console.log('removeAt: ', index)
              formArray.removeAt(+index);
            });

          this.listSectionConfigSignal!.update(
            (section: any) => {
              const key = section.key;
              const items = section[key];
              return {
                ...section,
                [key]: items.filter((_: any, idx: number) => !this.checkboxState[idx])
              };
            }
          );
        }));
      }

      effect(() => {
        const listSection = this.listSectionConfigSignal!();
        const { key, leftSide, rightSide, validators } = listSection;
        const items = listSection[key];
        const fields = [...leftSide, ...rightSide];
        const formArray = this.formGroup.get(key!) as FormArray;

        if (this.checkboxState.length !== items.length) {

          if (this.checkboxState.length > items.length) {
            this.itemsSubscription.unsubscribe();
            this.itemsSubscription = new Subscription();
            this.checkboxState = Array(items.length).fill(false);
          }

          if (this.checkboxState.length < items.length) {
            const last = items.length - 1;
            addFormGroupToFormArray({ formArray, fields, section: { [key]: [items[last]], key, validators } });
            this.checkboxState.push(false);
          }
        }
        console.log(this.checkboxState);

        formArray?.controls.forEach((formGroupItem: any, idx: number) => {
          const controls = (formGroupItem as FormGroup).controls;

          const fieldsOnChange = fields.filter(field => field.listenOnChange).map(({ key: field }) => controls[field].valueChanges.pipe(
            switchMap(value => this.utilityService.lookupReplacementUrl(value).pipe(map(fn => fn(value)))),
            switchMap(value => {
              controls[field].patchValue(value, { emitEvent: false });
              return of({ field, value });
            })
          ));
          this.itemsSubscription.add(
            merge(
              ...fieldsOnChange
            ).subscribe(({ field, value }: any) => {
              const listSection = this.listSectionConfigSignal!();
              const items = listSection[listSection.key];
              if (!value) {
                items[idx].images = items[idx].images.filter(({ title }: any) => title !== field);
                return;
              }

              const existed = items[idx].images.find(({ title }: any) => title === field);
              if (existed) {
                existed.src = value;
                existed.byPassCORSrc = `${environment.apiUrl}/order/get-file/${encodeURIComponent(value)}`;
                return;
              }

              items[idx].images = [
                ...items[idx].images, {
                  src: value,
                  title: field,
                  byPassCORSrc: `${environment.apiUrl}/order/get-file/${encodeURIComponent(value)}`
                }].sort((a, b) => imageOrder.indexOf(a.title) - imageOrder.indexOf(b.title));
            })
          );

          const fieldsOnCascade = fields.filter(field => field.cascadeFor).map(({ key: field, cascadeFor }) => controls[field].valueChanges.pipe(
            switchMap((data: any) => {
              if (data) {
                return of({ value: data, cascadeFor });
              }

              return of({ value: null, cascadeFor });
            })
          ));
          this.itemsSubscription.add(
            merge(
              ...fieldsOnCascade
            ).subscribe(({ value, cascadeFor }: any) => {
              controls[cascadeFor].patchValue(value);
            }))
        });
      });
    }

    const addFormGroupToFormArray = ({ formArray, section, fields }: any) => {
      Array(section[section.key].length || 1).fill(null).forEach((_, index: number) => {
        const formGroup = new FormGroup({}, { updateOn: 'blur', validators: section.validators.each });
        controls.single({ fields, formGroup, index, items: section[section.key] });
        formArray.push(formGroup);
        this.formGroup.addControl(section.key, formArray);
        if (section.disabled) {
          (this.formGroup.get(section.key) as FormControl).disable();
        }
      });
    }

    const controls: any = {
      'single': ({ fields, formGroup = this.formGroup, index, items = [] }: any) => {
        fields.forEach((field: any) => {
          const formControl = new FormControl({ value: field.value !== undefined ? field.value : items[index]?.[field.key], disabled: field.disabled }, field.validators);
          if (field.cascadeFor && field.httpRequest) {
            formControl.valueChanges.pipe(
              tap(() => {
                formGroup.controls[field.cascadeFor].patchValue('Loading...');
              }),
              switchMap((value): string => field.httpRequest(value)),
            ).subscribe({
              next: (value: any) => {
                formGroup.controls[field.cascadeFor].patchValue(value);
              },
              error: (error: any) => {
                console.log(error);
                formGroup.controls[field.cascadeFor].patchValue(null);
              }
            });
          }
          formGroup.addControl(field.key, formControl);
        });
      },
      'list': ({ fields, section }: any) => {
        const formArray: any = new FormArray([], section.validators.section);
        addFormGroupToFormArray({ formArray, fields, section });
      }
    };

    this.sectionConfigs?.map(({ rightSide, leftSide, ...section }: any) => ({
      section,
      fields: [...rightSide, ...leftSide]
    })).forEach(({ section, fields }: any) => {
      controls[section.type]({ fields, section });
    });
  };

  getConfirmDialog = (type: 'save' | 'cancel') => {
    const confirmType = {
      save: {
        message: 'Are you sure that you want to proceed?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptIcon: "none",
        rejectIcon: "none",
        rejectButtonStyleClass: "p-button-text",
      },
      cancel: {
        message: 'Do you want to delete this record?',
        header: 'Delete Confirmation',
        icon: 'pi pi-info-circle',
        acceptButtonStyleClass: "p-button-danger p-button-text",
        rejectButtonStyleClass: "p-button-text p-button-text",
      }
    };

    return new Observable((observer) => {
      this.confirmationService.confirm({
        ...confirmType[type],
        acceptIcon: "none",
        rejectIcon: "none",
        accept: () => {
          observer.next()
        }
      });
    });
  };

  getFormGroupValidity = (sectionName: string, formGroupIdx: number) => {
    const currentFormGroup = (this.formGroup.get(sectionName) as FormArray)?.controls[formGroupIdx];
    return currentFormGroup.valid || currentFormGroup.disabled;
  };
}
