import { Component } from '@angular/core';
import { FormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from '../../services/authentication.service';
import { error } from 'console';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-user-activation',
  standalone: true,
  imports: [ReactiveFormsModule, SharePrimeNGModule],
  templateUrl: './user-activation.component.html',
  styleUrl: './user-activation.component.css',
  providers: [MessageService],

})
export class UserActivationComponent {
  constructor(private fb: FormBuilder,
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute) {
      const email = this.route.snapshot.queryParams['email'];
      this.activationForm.setValue({
        email: email,
        verificationCode: ''
      })
  }
  activationForm = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    verificationCode: ['', Validators.required]
  }, {})

  activateAccount() {
    this.authenticationService.activateUser({ "email": this.activationForm.value.email, "verificationCode": this.activationForm.value.verificationCode }).subscribe({
      next: (response) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Account activation successful!',
          sticky: true,
        });
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 2000);
      },
      error: (e) => {
        const message = e.error.message || e.message || e.messages;
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: message,
          sticky: true,
        });
      }
    })
  }
}
