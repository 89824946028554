<div class="grid">
    @for(statistic of overallStatistics; track statistic.name;) {
    <div class="col-12 md:col-4">
        <div class="card flex w-full relative h-14rem">
            <div class="flex w-full justify-content-between p-1">
                <div>
                    <span class="block white-space-nowrap font-semibold">{{statistic.name}}</span>
                    <span class="block font-semibold text-xl mt2 white-space-nowrap">{{statistic.value}}</span>
                </div>
                <div class="text-right">
                    <span class="block white-space-nowrap">{{statistic.delta | number: '1.0-0'}}%
                        @if (statistic.delta < 0) { <i class="pi pi-arrow-down text-red-500"></i>
                            } @else if (statistic.delta > 0) {
                            <i class="pi pi-arrow-up text-green-500"></i>
                            }
                    </span>
                    <span class="block text-color-secondary mt-2 white-space-nowrap">vs Last week</span>
                    ({{statistic.lastWeekValue}})
                </div>
            </div>
            <div class="absolute w-full bottom-0 left-0">
                <ng-container [ngTemplateOutlet]="statistic.line"></ng-container>
            </div>
        </div>
    </div>
    }
    @for (card of cards; track card.header; let colorPatternIdx = $index;) {
    <div class="col-12 md:col-6 xl:col-3 color-patterns-{{colorPatternIdx % 4 + 1}}">
        <div class="card shadow-1 flex flex-column card-border-color">
            <div class="flex align-items-center">
                <div class="flex justify-content-center align-items-center p-2 border-round card-background-color">
                    <i class="pi {{card.icon}} icon-color"></i>
                </div>
                <span class="text-xl ml-2 font-semibold card-color">{{card.header}}</span>
            </div>
            <div class="grid mt-3">
                @if(card.leftStatitic) {
                <div class="col flex flex-column p-3 text-center border-right-1 surface-border">
                    <span class="text-color text-2xl font-semibold">{{card.leftStatitic.value}}</span>
                    <span class="text-color font-semibold">{{card.leftStatitic.name}}</span>
                </div>
                }
                @if(card.rightStatitic) {
                <div class="col flex flex-column p-3 text-center">
                    <span class="text-color text-2xl font-semibold">{{card.rightStatitic.value}}</span>
                    <span class="text-color font-semibold">{{card.rightStatitic.name}}</span>
                </div>
                }
            </div>
        </div>
    </div>
    }
</div>

<ng-template #line1>
    <svg viewBox="0 0 368 81" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M-0.073585 35.8779L-1 35.9463V36.8752V98.0132V99.0051L-0.00811612 99.0131L367.992 102L369 102.008V101V73.2548V53.3427C369 44.4468 361.455 37.4177 352.581 38.0471L323.849 40.0847C313.082 40.8483 302.461 37.2465 294.38 30.0911L276.28 14.0653C258.265 -1.88527 231.553 -3.07505 212.192 11.2108L191.557 26.4364C186.285 30.3259 180.287 33.1189 173.918 34.6502L134.479 44.1318C126.726 45.9956 118.636 45.9394 110.91 43.968L69.4857 33.3979C64.1455 32.0353 58.6198 31.5472 53.1234 31.9528L-0.073585 35.8779Z"
            fill="url(#paint0_linear_234_6804)" fill-opacity="0.2" stroke="#C63737" stroke-width="2" />
        <defs>
            <linearGradient id="paint0_linear_234_6804" x1="184" y1="-2" x2="184" y2="101"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#C63737" />
                <stop offset="1" stop-color="#C63737" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</ng-template>


<ng-template #line2>
    <svg viewBox="0 0 368 74" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M-0.0735851 38.8779L-1 38.9463V39.8752V91.0132V92.0051L-0.00811612 92.0131L367.992 95L369 95.0081V94V36.2548V16.3427C369 9.11118 360.806 4.92425 354.946 9.16159L323.893 31.6152C313.862 38.8689 300.14 38.1916 290.872 29.9852L275.842 16.6772C258.083 0.952962 231.75 -0.219925 212.663 13.8632C195.623 26.4364 172.377 26.4364 155.337 13.8632L152.649 11.8795C134.793 -1.29549 110.467 -1.41251 92.4851 11.5901L72.2319 26.235C64.8736 31.5557 56.1816 34.7271 47.1258 35.3953L-0.0735851 38.8779Z"
            fill="url(#paint0_linear_234_6714)" fill-opacity="0.2" stroke="#2196F3" stroke-width="2" />
        <defs>
            <linearGradient id="paint0_linear_234_6714" x1="184" y1="-9" x2="184" y2="94"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#2196F3" />
                <stop offset="1" stop-color="#2196F3" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>

</ng-template>

<ng-template #line3>
    <svg viewBox="0 0 368 69" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M-0.230454 13.9021L-1 14.0844V14.8752V86.0132V87.0051L-0.00811612 87.0131L367.992 90L369 90.0081V89V51.2548V31.3427C369 23.2575 360.845 17.7208 353.33 20.7042L311.748 37.2126C308.222 38.6126 304.513 39.5014 300.735 39.8521L260.86 43.5542C250.895 44.4794 240.886 42.3311 232.178 37.3982L201.651 20.105C190.405 13.734 177.159 11.8709 164.592 14.8922L138.329 21.2061C128.207 23.6395 117.574 22.7861 107.97 18.7696L76.7157 5.69887C66.7741 1.54121 55.7711 0.639357 45.2853 3.12271L-0.230454 13.9021Z"
            fill="url(#paint0_linear_234_6754)" fill-opacity="0.2" stroke="#4DB6AC" stroke-width="2" />
        <defs>
            <linearGradient id="paint0_linear_234_6754" x1="184" y1="-14" x2="184" y2="89"
                gradientUnits="userSpaceOnUse">
                <stop stop-color="#4DB6AC" />
                <stop offset="1" stop-color="#4DB6AC" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</ng-template>