import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-simple-pc-table',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './simple-pc-table.component.html',
  styleUrl: './simple-pc-table.component.css'
})
export class SimplePcTableComponent {
  @Input()
  cols: any[] = [];

  @Input()
  items: any[] = [];
}
