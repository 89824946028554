<ngx-extended-pdf-viewer #pdfViewer [base64Src]="base64Src" [zoom]="'auto'" [customToolbar]="customToolbar"
    (pagesLoaded)="onPagesLoaded($event)" (pdfLoaded)="onEvent('pdfLoaded', $event)"
    (annotationEditorLayerRendered)="onEvent('annotationEditorLayerRendered', $event)">
</ngx-extended-pdf-viewer>

<ng-template #customToolbar>
    <div id="toolbarViewer">
        <div class="toolbarButtonSpacer"></div>
        <pdf-zoom-toolbar></pdf-zoom-toolbar>
        <div id="toolbarViewerRight">
            <pdf-print></pdf-print>
            <!-- <app-open-in-new-tab></app-open-in-new-tab> -->
            <pdf-download></pdf-download>
            <div class="verticalToolbarSeparator hiddenSmallView"></div>
        </div>
    </div>
</ng-template>