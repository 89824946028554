<p-toolbar>
    <div class="p-toolbar-group-start">
        @if (configs.leftButtons) {
        @for(button of configs.leftButtons; track button.label; let last = $last) {
        <p-button [raised]="true" icon="pi {{button.icon}}" [label]="button.label" [class.mr-2]="!last"
            [disabled]="button.disabled" [severity]="button.severity"
            (click)="button.event && button.event.next($event)" [size]="button.size" />
        }
        }
        @if (configs.leftText) {
        <span [class]="configs.leftText.size || 'text-3xl'">{{configs.leftText.text}}</span>
        }
    </div>

    @if (configs.rightButtons) {
    <div class="p-toolbar-group-end">
        @for(button of configs.rightButtons; track button.label; let last = $last) {
        <p-button [raised]="true" [text]="button.text" icon="pi {{button.icon}}" [label]="button.label"
            [disabled]="button.disabled" [size]="button.size" [class.mr-2]="!last" [severity]="button.severity"
            (click)="button.event && button.event.next($event)" />
        }
    </div>
    }
    <ng-content></ng-content>
</p-toolbar>
