<!-- <app-line-chart-legend></app-line-chart-legend> -->
<div class="grid">
    @for(config of chartConfigs; track config.label; let idx = $index) {
        <div class="col-6 md:col-3 relative transition-all transition-duration-300 hover:shadow-3 cursor-pointer h-5rem" (click)="toggleChart(config.status)">
            <span class="flex align-items-center">
                <i class="pi {{config.icon}} mr-1"></i> {{config.label}}
            </span>
            <div class="col-12">
                <ng-container [ngTemplateOutlet]="config.line"
                    [ngTemplateOutletContext]="{ strokeColor: config.borderColor, lineColor: config.backgroundColor }"></ng-container>
            </div>
        </div>
    }
    <div class="col-12 mt-3">
        <p-chart #chart type="line" [data]="data" [options]="options" [responsive]="true" />
    </div>
</div>

<ng-template #line1 let-strokeColor="strokeColor" let-lineColor="lineColor">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 250 100">
        <defs>
            <path id="a" d="M0 0h249v33H0z" />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <mask id="b" fill="#fff">
                <use xlink:href="#a" />
            </mask>
            <use fill-rule="nonzero" xlink:href="#a" />
            <path [attr.fill]="lineColor" fill-rule="nonzero" [attr.stroke]="strokeColor" stroke-width="2" transform="scale(1, 2)"
                d="M24.569 17.497l22.403 8.354 26.243-1.693 26.941-16.375 39.878-1.93 38.712 18.07L215.344.387 251.5 17.902V34.5h-253V.147l26.069 17.35z"
                mask="url(#b)" />
        </g>
    </svg>
</ng-template>

<ng-template #line2 let-strokeColor="strokeColor" let-lineColor="lineColor">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 250 100">
        <defs>
            <path id="a" d="M0 0h249v33H0z" />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <mask id="b" fill="#fff">
                <use xlink:href="#a" />
            </mask>
            <use fill-rule="nonzero" xlink:href="#a" />
            <path [attr.fill]="lineColor" fill-rule="nonzero" [attr.stroke]="strokeColor" stroke-width="2" transform="scale(1, 2)"
                d="M44.722 18.84l26.915-1.716 34.298 5.606 39.434-10.85 40.553-2.235 35.528 11.688 30.05-22.2V34.5h-256V24.244L21.072 2.971l23.65 15.868z"
                mask="url(#b)" />
        </g>
    </svg>
</ng-template>

<ng-template #line3 let-strokeColor="strokeColor" let-lineColor="lineColor">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 250 100">
        <defs>
            <path id="a" d="M0 0h249v33H0z" />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <mask id="b" fill="#fff">
                <use xlink:href="#a" />
            </mask>
            <use fill-rule="nonzero" xlink:href="#a" />
            <path [attr.fill]="lineColor" fill-rule="nonzero" [attr.stroke]="strokeColor" stroke-width="2" transform="scale(1, 2)"
                d="M23.728 13.242l22.81-9.489 27.072 10.36 33.48-2.34 39.313-7.964 39.258 15.703 35.325-18.106L250.5 8.94V34.5h-252V4.87l25.228 8.372z"
                mask="url(#b)" />
        </g>
    </svg>
</ng-template>

<ng-template #line4 let-strokeColor="strokeColor" let-lineColor="lineColor">
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 250 100">
        <defs>
            <path id="a" d="M0 0h249v33H0z" />
        </defs>
        <g fill="none" fill-rule="evenodd">
            <mask id="b" fill="#fff">
                <use xlink:href="#a" />
            </mask>
            <use fill-rule="nonzero" xlink:href="#a" />
            <path [attr.fill]="lineColor" fill-rule="nonzero" [attr.stroke]="strokeColor" stroke-width="2" transform="scale(1, 2)"
                d="M46.08 16.666L72.408 4.011l30.653 10.685L144.416 3.84l39.437 5.854 34.684 11.735 28.963-8.315V34.5h-249V16.033L23.556 3.126l22.523 13.54z"
                mask="url(#b)" />
        </g>
    </svg>
</ng-template>