import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, throwError } from 'rxjs';
import { BatchUpdateVariant, PriceTier, Product, Variant, VariantQuery } from '../models/product.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private apiUrl = `${environment.apiUrl}/product`;
  private apiVariantUrl = `${environment.apiUrl}/variants`;

  constructor(private http: HttpClient) {}

  createProduct(product: Product): Observable<Product> {
    return this.http.post<Product>(`${this.apiUrl}/create`, product);
  }

  updateProduct(product: Product): Observable<Product> {
    return this.http.post<Product>(`${this.apiUrl}/update`, product);
  }

  findAll(): Observable<Product[]> {
    return this.http.get<Product[]>(`${this.apiUrl}/findAll`);
  }

  syncVariants(): Observable<void> {
    return this.http.get<void>(`${this.apiUrl}/syncVariants`);
  }

  getProductById(id: any): Observable<Product> {
    return this.http.get<Product>(`${this.apiUrl}/${id}`);
  }

  deleteProduct(product: Product): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/delete/${product.id}`);
  }

  findVariants(query: VariantQuery): Observable<Variant[]> {
    const params = { ...query };
    return this.http.get<Variant[]>(`${this.apiUrl}/findAllVariants`, { params });
  }

  updateVariant(variant: Variant): Observable<Variant> {
    return this.http.post<Variant>(`${this.apiUrl}/updateVariant`, variant);
  }

  batchUpdateVariants(variant: BatchUpdateVariant): Observable<number> {
    return this.http.post<number>(`${this.apiVariantUrl}/batchUpdateVariants`, variant).pipe(
      catchError((error) => {
        return throwError(() => error);
      })
    );
  }

  findPricingTiers(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiVariantUrl}/get-pricing-tiers`);
  }

  getDefaultVariantDesignSheetID(): Observable<any> {
    return this.http.get<any>(`${this.apiVariantUrl}/get-variant-design-sheet-id`);
  }

  addNewPriceTier(tier: PriceTier): Observable<PriceTier> {
    return this.http.post<PriceTier>(`${this.apiVariantUrl}/add-new-price-tier`, tier);
  }

  deletePriceTier(tierId: number): Observable<void> {
    return this.http.delete<void>(`${this.apiVariantUrl}/delete-price-tier/${tierId}`);
  }

  findPricingRules(): Observable<any[]> {
    return this.http.get<any[]>(`${this.apiVariantUrl}/get-pricing-rules`);
  }
}
