import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { RegisterService } from './register.service';
import { Router } from '@angular/router';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { DividerModule } from 'primeng/divider';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { PanelModule } from 'primeng/panel';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { FooterComponent } from '../../shared-ui/footer/footer.component';
import { AuthenticationService } from '../../services/authentication.service';
import { CalendarModule } from 'primeng/calendar';
import { phoneValidator } from '../../shared-ui/validators/phone-validator';
import { passwordMatchValidator } from '../../shared-ui/validators/password-match.validator';
import { userNameValidator } from '../../shared-ui/validators/username-validator';
import { passwordValidator } from '../../shared-ui/validators/password.validator';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    FooterComponent,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    PasswordModule,
    ButtonModule,
    DividerModule,
    CardModule,
    ToastModule,
    DropdownModule,
    PanelModule,
    CalendarModule,
  ],
  providers: [MessageService],
  templateUrl: './register.component.html',
  styleUrl: './register.component.css',
})
export class RegisterComponent {
  showForm = true;
  yearRange: string = '';
  maxDate: Date;
  defaultDate : Date;
  constructor(
    private fb: FormBuilder,
    private registerService: RegisterService,
    private messageService: MessageService,
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    const currentYear = new Date().getFullYear();
    this.yearRange = `${currentYear - 100}:${currentYear - 10}`;
    const today = new Date();
    this.maxDate = this.maxDate = new Date(today.setFullYear(today.getFullYear() - 13));
    this.defaultDate = new Date(this.maxDate); // Set the default date to maxDate
  }

  registerForm = this.fb.group(
    {
      firstName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/)]],
      email: ['', [Validators.required, Validators.email]],
      userName: ['', [Validators.required, userNameValidator]],
      address: ['', []],
      dateOfBirth: ['', []],
      phone: ['', [phoneValidator]],
      password: ['', [Validators.required, passwordValidator]],
      confirmPassword: ['', Validators.required],
    },
    {
      validators: [passwordMatchValidator],
    }
  );

  get firstName() {
    return this.registerForm.controls['firstName'];
  }

  get lastName() {
    return this.registerForm.controls['lastName'];
  }

  get phone() {
    return this.registerForm.controls['phone'];
  }

  get email() {
    return this.registerForm.controls['email'];
  }

  get userName() {
    return this.registerForm.controls['userName'];
  }

  get address() {
    return this.registerForm.controls['address'];
  }

  get dateOfBirth() {
    return this.registerForm.controls['dateOfBirth'];
  }

  get password() {
    return this.registerForm.controls['password'];
  }

  get confirmPassword() {
    return this.registerForm.controls['confirmPassword'];
  }

  submitDetails() {
    const postData = { ...this.registerForm.value };
    delete postData.confirmPassword;
    console.log(postData);
    this.authenticationService.signUp(postData).subscribe({
      next: (res) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'Your account has been successfully created! You will be redirected to the login page in 5 seconds.',
          sticky: true,
        });

        // Hide the form and open sign page after 5 seconds
        this.showForm = false;
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 5000);
      },
      error: (e) => {
        console.log(e);
        const message = e.error.message;
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: message,
          sticky: true,
        });
      },
      complete: () => {},
    });
  }
}
