<br />
<form [formGroup]="loginForm" class="justify-content-center" style="display: flex;" (ngSubmit)="submitDetails()">
  <p-card header="Login" class="justify-content-center" [style]="{width: '360px'}">
      <div class="field">
          <label for="userName">Email Or User Name</label>
          <input type="text" pInputText formControlName="userName"
              class="w-full h-3rem border-round-md border-1 border-primary-400" name="userName"
              placeholder="Type your email" />
          <small *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="block p-error">
              <div *ngIf="userName.errors?.['required']">Email or UserName is required.</div>
          </small>
      </div>
      <div class="field">
          <label for="password">Password</label>
          <p-password [toggleMask]="true" placeholder="Type your password" [inputStyle]="{width : '286px'}"
              formControlName="password" [feedback]="false"/>
          <small *ngIf="password.invalid && (password.dirty || password.touched)" class="block p-error">
              <div *ngIf="password.errors?.['required']">Password is required.</div>
          </small>
      </div>
      <p-button label="Login" styleClass="w-full" [disabled]="loginForm.invalid" type="submit"></p-button>
  </p-card>
</form>
<br>
<p-toast position="top-center"></p-toast>
