import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap } from 'rxjs';
import { Order } from '../models/order.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private apiUrl = `${environment.apiUrl}/order`;

  constructor(private http: HttpClient) { }

  createOrder(order: Order): Observable<Order> {
    return this.http.post<Order>(this.apiUrl + '/create', order);
  }

  findAll(): Observable<Order[]> {
    return this.http.get<Order[]>(this.apiUrl + '/findAll');
  }

  readonly getOrderStatusConfigs = (): Observable<any> => {
    return this.http.get(`${environment.apiUrl}/setting/ORDER_STATUS`).pipe(
      switchMap((({ value }: any) => {
        const statuses = value.split(/;\s?/g);
        return of(statuses)
      }))
    );
  };

  readonly getOrderInPriorityAfter = (): Observable<any> => {
    return this.http.get(`${environment.apiUrl}/setting/ORDER_IN_PRIORITY_AFTER`).pipe(
      switchMap((({ value }: any) => {
        return of(+value)
      }))
    );
  };

  readonly getVariants = (): Observable<any> => {
    return this.http.get(`${environment.apiUrl}/variants/get-variants`);
  }

  readonly getOrdersByFilter = (filter?: any): Observable<{ totalRecords: number; data: any[] }> => {
    return this.http.post<any>(`${this.apiUrl}/get-orders-by-fitlers`, filter);
  }

  readonly getOrdersByStatus = (): Observable<any> => {
    return this.http.get(`${this.apiUrl}/count-orders-by-status`)
  };

  readonly getTrackingNumberByPdf = (url: string): Observable<any> => {
    if (!url) {
      throw ('URL is null/empty!');
    }
    return this.http.get(`${this.apiUrl}/extract-tracking-number/${encodeURIComponent(url)}`);
  };

  readonly updateOrder = (order: Order): Observable<any> => {
    return this.http.patch<Order>(`${this.apiUrl}/update`, order);
  }

  readonly getStoreAndSellerFilterOptions = (): Observable<any> => {
    return this.http.get(`${this.apiUrl}/get-store-seller-options`)
  };

  readonly updateStatus = (orderId: number, status: string): Observable<any> => this.http.get(`${this.apiUrl}/${orderId}/status/${status}`)
}
