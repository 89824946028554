import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    constructor(private http: HttpClient) { }
    //api/report/get-total-amount-and-quantity-order

    getTotalAmountAndQuantityOrder = () => this.http.get(`${environment.apiUrl}/report/get-total-amount-and-quantity-order`);
    getStoreAndSellerOptions = () => this.http.get(`${environment.apiUrl}/report/get-store-seller-options`);
    getReportsByFilter = (filter: any) => this.http.post(`${environment.apiUrl}/report/get-report-by-fitlers`, filter);
}