<div class="relative overflow-hidden flex flex-column justify-content-center">
  <div class="absolute top-0 bg-shape-top bg-no-repeat bg-cover w-full"></div>
  <div id="home" class="landing-wrapper z-1">
    <div class="px-4 md:px-8 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
      <div class="flex gap-2 align-items-center text-white mr-0 lg:mr-6 select-none">
        <img src="../../../assets/images/headers/logo.png" class="w-2rem h-2rem" />
        <span class="font-bold text-3xl">PrintHubPod</span>
      </div>
      <a pripple class="p-element cursor-pointer block lg:hidden text-white">
        <i class="pi pi-bars text-4xl"></i>
      </a>
      <div
        id="menu"
        class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 top-100 z-1 shadow-2 lg:shadow-none md:bg-transparent"
      >
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
          @for(menuItem of topNavMenu; track menuItem.label) {
          <li>
            <a
              routerLink="{{ menuItem.url }}"
              class="p-element flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 lg:text-white text-lg white-space-nowrap"
              >{{ menuItem.label }}</a
            >
          </li>
          }
        </ul>
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
          @for(menuItem of topLeftNavMenu; track menuItem.label; let last = $last) {
          <li>
            <a
              [routerLink]=""
              (click)="onClick(menuItem.url)"
              class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 text-lg white-space-nowrap"
              [class]="{ 'surface-100': last, 'text-900': last, 'lg:text-white': !last }"
              >{{ menuItem.label }}</a
            >
            <ng-template #noLink>
              <span
                class="flex px-6 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100 font-medium border-round cursor-pointer transition-colors transition-duration-150 text-lg white-space-nowrap"
                [class]="{ 'surface-100': last, 'text-900': last, 'lg:text-white': !last }"
                >{{ menuItem.label }}</span
              >
            </ng-template>
          </li>
          }
        </ul>
      </div>
    </div>
    <div class="front-content px-4 md:px-8 z-2">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
