import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { OrderTracking, TrackingQuery } from '../models/order.tracking.model';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private apiUrl = `${environment.apiUrl}/tracking`;

  constructor(private http: HttpClient) { }

  findOrderTrackings(offset: number, limit: number, query: TrackingQuery = {}): Observable<{ total: number; data: OrderTracking[] }> {
    const params = Object.entries({
      offset: offset,
      limit: limit,
      ...query,
    }).map(([key, value]) => `${key}=${value}`).join('&');

    return this.http.get(`${this.apiUrl}/findOrderTrackings?${params}`).pipe(
      map((response: any) => response),
    );

  }

  findAllTrackingStatus(): Observable<string[]> {
    return this.http.get(`${this.apiUrl}/findAllTrackingStatus`).pipe(
      map((response: any) => response),
    );
  }

  refreshOrderTrackings(orderTrackings: any[]): Observable<OrderTracking[]> {
    return this.http.post(`${this.apiUrl}/refreshOrderTrackings`, orderTrackings).pipe(
      map((response: any) => response),
    );
  }
}
