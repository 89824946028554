<app-product-toolbar></app-product-toolbar>
<br />
<p-toolbar>
  <div class="p-toolbar-group-start">
    <strong class="mr-2">Pricing Tier: </strong>
    <p-dropdown
      [options]="optionPricingTiers"
      optionLabel="name"
      [(ngModel)]="selectedPricingTier"
      (onChange)="onChangePricingTier()"
      class="mr-2"
    ></p-dropdown>
    <p-button
      *ngIf="linkVariantDesign"
      icon="pi pi-external-link"
      pTooltip="View Designs from Google Spreadsheet"
      tooltipPosition="top"
      label="View Designs"
      severity="warning"
      [text]="true"
      (click)="openGoogleSpreadSheet()"
    />
  </div>
  <div class="p-toolbar-group-center">
    <strong class="mr-2">Style: </strong>
    <p-dropdown [options]="optionStyles" [(ngModel)]="selectedSearch.style" (onChange)="onSearch()" [style]="{ 'min-width': '150px' }" />
    <strong class="mr-2 ml-2">Size: </strong>
    <p-dropdown [options]="optionSizes" [(ngModel)]="selectedSearch.size" (onChange)="onSearch()" [style]="{ 'min-width': '150px' }" />
    <strong class="mr-2 ml-2">Color: </strong>
    <p-dropdown [options]="optionColors" [(ngModel)]="selectedSearch.color" (onChange)="onSearch()" [style]="{ 'min-width': '150px' }"></p-dropdown>
    <div class="p-field-row">
      <p-button icon="pi pi-search-plus" label="Search" severity="success" class="p-button-success mr-2 ml-2" (click)="onSearch()" />
      <p-button icon="pi pi-eraser" label="Clear" severity="success" (click)="onClearSearch()" class="p-button-success" class="mr-2" />
    </div>
  </div>
  <div class="p-toolbar-group-end">
    <p-button icon="pi pi-pen-to-square" label="Batch Update" severity="warning" class="p-button-warning" (click)="onShowBathUpdateDialog()" />
  </div>
</p-toolbar>
<p-toast></p-toast>
<p-confirmDialog [style]="{ width: '30vw' }"></p-confirmDialog>
<p-dialog header="Batch Update for Variants" [modal]="true" [(visible)]="showBatchUpdateDialog">
  <div class="flex align-items-center gap-3 mb-3">
    <label for="tiers" class="font-semibold w-8rem">Pricing Tier:</label>
    <p-dropdown id="tiers" [options]="optionPricingTiers" optionLabel="name" [disabled]="true" [(ngModel)]="selectedPricingTier" />
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="products" class="font-semibold w-8rem">Product</label>
    <p-dropdown
      id="products"
      [options]="optionProducts"
      optionLabel="name"
      placeholder="Select a product"
      (onChange)="onProductChange($event)"
      [style]="{ 'min-width': '350px' }"
    >
      <ng-template pTemplate="item" let-i> {{ i.brand }}: {{ i.code }} - {{ i.style }} - {{ i.name }} </ng-template>
    </p-dropdown>
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label for="size" class="font-semibold w-8rem">Size</label>
    <p-multiSelect
      id="size"
      [options]="sizes"
      optionLabel="size"
      optionValue="size"
      [(ngModel)]="selectedSizes"
      placeholder="Select sizes"
      (onChange)="onProductSizeChange($event)"
      [style]="{ 'min-width': '350px' }"
    ></p-multiSelect>
  </div>
  <div class="flex align-items-center gap-3 mb-3">
    <label class="font-semibold w-8rem"></label>
    <table class="variant-update-form">
      <tr>
        <!-- Weight -->
        <td>
          <div>
            <label for="weight">Weight:</label>
            <input id="weight" pInputText [(ngModel)]="batchUpdateVariant.weight" />
          </div>
        </td>

        <!-- Length -->
        <td>
          <div>
            <label for="length">Length:</label>
            <input id="length" pInputText [(ngModel)]="batchUpdateVariant.length" />
          </div>
        </td>

        <!-- Width -->
        <td>
          <div>
            <label for="width">Width:</label>
            <input id="width" pInputText [(ngModel)]="batchUpdateVariant.width" />
          </div>
        </td>

        <!-- Height -->
        <td>
          <div>
            <label for="height">Height:</label>
            <input id="height" pInputText [(ngModel)]="batchUpdateVariant.height" />
          </div>
        </td>
      </tr>
      <tr>
        <!-- Base Cost -->
        <td>
          <div>
            <label for="basecost">Base Cost:</label>
            <input id="basecost" pInputText [(ngModel)]="batchUpdateVariant.basecost" />
          </div>
        </td>

        <!-- Price Two Side -->
        <td>
          <div>
            <label for="price_two_side">Price 2nd Side:</label>
            <input id="price_two_side" pInputText [(ngModel)]="batchUpdateVariant.price_2nd_side" />
          </div>
        </td>

        <!-- Price Sleeve Print -->
        <td>
          <div>
            <label for="price_sleeve">Price Sleeve:</label>
            <input id="price_sleeve" pInputText [(ngModel)]="batchUpdateVariant.price_sleeve" />
          </div>
        </td>

        <!-- Price Shipping -->
        <td>
          <div>
            <label for="price_shipping">Price Shipping:</label>
            <input id="price_shipping" pInputText [(ngModel)]="batchUpdateVariant.price_shipping" />
          </div>
        </td>

        <!-- Price Shipping 2nd Item: -->
        <td>
          <div>
            <label for="price">Price Shipping 2nd Item:</label>
            <input id="price" pInputText [(ngModel)]="batchUpdateVariant.price_shipping_2nd_item" />
          </div>
        </td>
      </tr>
    </table>
  </div>
  <div class="flex align-items-center gap-3 mb-5"></div>
  <div class="flex align-items-center gap-3 mb-3">
    <label class="font-semibold w-8rem"></label>
    <p-button icon="pi pi-save" label="Save" (onClick)="onBatchUpdate()"></p-button>
    <p-button icon="pi pi-times-circle" label="Cancel" severity="secondary" (onClick)="showBatchUpdateDialog = false"></p-button>
  </div>
</p-dialog>

<br />
<p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '3px' }" />
<div class="card">
  <p-table
    [value]="variants"
    dataKey="id"
    [paginator]="true"
    [rows]="20"
    class="p-datatable-customers"
    currentPageReportTemplate="Showing {first} to {last} of {{ variants.length }}"
    [showCurrentPageReport]="true"
    [scrollable]="true"
    scrollHeight="flex"
  >
    <!-- Header -->
    <ng-template pTemplate="header">
      <tr>
        <th>ID</th>
        <th>Style</th>
        <th>Size</th>
        <th>Color</th>
        <th class="number">Weight</th>
        <th class="number">Length</th>
        <th class="number">Width</th>
        <th class="number">Height</th>
        <th class="number">Basecost</th>
        <th class="number">Price 2nd side</th>
        <th class="number">Price Sleeve</th>
        <th class="number">Price Shipping</th>
        <th class="number">Price Shipping 2nd Item</th>
        <th class="column-action">Action</th>
      </tr>
    </ng-template>

    <!-- Body -->
    <ng-template pTemplate="body" let-variant>
      <ng-container *ngFor="let price of variant.prices">
        <tr class="data-row">
          <td>{{ variant.id }}</td>
          <td>{{ variant.style }}</td>
          <td>{{ variant.size }}</td>
          <td>{{ variant.color }}</td>
          <td class="number">
            <span *ngIf="!variant.isEditMode">{{ variant.weight }}</span>
            <input *ngIf="variant.isEditMode" pInputText [(ngModel)]="variant.weight" />
          </td>
          <td class="number">
            <span *ngIf="!variant.isEditMode">{{ variant.length }}</span>
            <input *ngIf="variant.isEditMode" pInputText [(ngModel)]="variant.length" />
          </td>
          <td class="number">
            <span *ngIf="!variant.isEditMode">{{ variant.width }}</span>
            <input *ngIf="variant.isEditMode" pInputText [(ngModel)]="variant.width" />
          </td>
          <td class="number">
            <span *ngIf="!variant.isEditMode">{{ variant.height }}</span>
            <input *ngIf="variant.isEditMode" pInputText [(ngModel)]="variant.height" />
          </td>
          <td class="number">
            <span *ngIf="!variant.isEditMode">${{ price.basecost }}</span>
            <input *ngIf="variant.isEditMode" pInputText [(ngModel)]="price.basecost" />
          </td>
          <td class="number">
            <span *ngIf="!variant.isEditMode">${{ price.price_2nd_side }}</span>
            <input *ngIf="variant.isEditMode" pInputText [(ngModel)]="price.price_2nd_side" />
          </td>
          <td class="number">
            <span *ngIf="!variant.isEditMode">${{ price.price_sleeve }}</span>
            <input *ngIf="variant.isEditMode" pInputText [(ngModel)]="price.price_sleeve" />
          </td>
          <td class="number">
            <span *ngIf="!variant.isEditMode">${{ price.price_shipping }}</span>
            <input *ngIf="variant.isEditMode" pInputText [(ngModel)]="price.price_shipping" />
          </td>
          <td class="number">
            <span *ngIf="!variant.isEditMode">${{ price.price_shipping_2nd_item }}</span>
            <input *ngIf="variant.isEditMode" pInputText [(ngModel)]="price.price_shipping_2nd_item" />
          </td>
          <td class="column-action">
            <button
              pButton
              icon="pi pi-pencil"
              *ngIf="!variant.isEditMode"
              pTooltip="Update"
              tooltipPosition="top"
              (click)="editVariant(variant)"
              class="small-button p-button-rounded p-button-success"
            ></button>
            <button
              pButton
              icon="pi pi-check"
              *ngIf="variant.isEditMode"
              pTooltip="Save"
              tooltipPosition="top"
              (click)="saveVariant(variant)"
              class="small-button p-button-rounded p-button-success"
              style="background: green"
            ></button>
            <button
              pButton
              icon="pi pi-times"
              *ngIf="variant.isEditMode"
              pTooltip="Cancel"
              tooltipPosition="top"
              (click)="cancelEditVariant(variant)"
              class="small-button p-button-rounded p-button-success"
              style="background: red"
            ></button>
          </td>
        </tr>
      </ng-container>
    </ng-template>
  </p-table>
</div>
