import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { ProductToolbarComponent } from '../../shared-ui/product-toolbar/product-toolbar.component';
import { ProductService } from '../../services/product.service';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-pricing-rules',
  standalone: true,
  imports: [CommonModule, SharePrimeNGModule, ProductToolbarComponent],
  providers: [ConfirmationService, MessageService],
  templateUrl: './pricing-rules.component.html',
  styleUrl: './pricing-rules.component.css',
})
export class PricingRulesComponent {
  pricingRules: any[] = [];
  pricingRulesOriginal: any[] = [];
  optionStyles: any[] = [];
  optionSizes: any[] = [];
  selectedSearch: any = {
    style: '',
    size: '',
  };
  isLoading = false;

  constructor(private productService: ProductService, private confirmationService: ConfirmationService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.getPriceRules();
  }

  getPriceRules() {
    this.isLoading = true;
    this.productService.findPricingRules()
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe({
      next: (response) => {
        this.pricingRules = response;
        this.pricingRulesOriginal = this.pricingRules;

        this.optionStyles = [...new Set(this.pricingRules.map((rule) => rule.style))];
        this.optionSizes = [...new Set(this.pricingRules.map((rule) => rule.size))];
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: `Error loading pricing rules: ${err}`,
          life: 10000,
        });
      },
    });
  }

  onSearch() {
    this.pricingRules = this.pricingRulesOriginal.filter((rule) => {
      return (!this.selectedSearch.style || rule.style === this.selectedSearch.style) && (!this.selectedSearch.size || rule.size === this.selectedSearch.size);
    });
  }

  onClearSearch() {
    this.selectedSearch = { style: '', size: '' };
    this.pricingRules = [...this.pricingRulesOriginal];
  }
}
