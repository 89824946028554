<form [formGroup]="searchForm">
  <p-toolbar>
    <div class="p-toolbar-group-start"></div>
    <div class="p-toolbar-group-center">
      <p-dropdown
        class="mr-2"
        formControlName="status"
        [options]="trackingStatuses"
        optionLabel="label"
        optionValue="value"
        placeholder="Select Tracking Status"
        pTooltip="Select Tracking Status to search"
        tooltipPosition="top"
      ></p-dropdown>
      <input pInputText class="mr-2" formControlName="orderId" placeholder="Order ID" pTooltip="Enter Order ID to search" tooltipPosition="top" />
      <input
        pInputText
        class="mr-2"
        formControlName="trackingNumber"
        placeholder="Tracking Number"
        pTooltip="Enter Tracking Number to search"
        tooltipPosition="top"
      />
      <p-button icon="pi pi-search" label="Search" class="p-button-success mr-2" (click)="onSearch()" />
      <p-button icon="pi pi-eraser" label="Clear" class="p-button-success" (click)="onClear()" />
    </div>
    <div class="p-toolbar-group-end">
      <p-button [icon]="isRefreshing ? 'pi pi-sync pi-spin' : 'pi pi-sync'" label="Refresh" class="p-button-success" (click)="onRefreshCurrentView()" />
    </div>
  </p-toolbar>
</form>
<div class="card">
  <p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '3px' }" />
  <p-table [value]="orderTrackings" [paginator]="false" [scrollable]="true" scrollHeight="flex">
    <ng-template pTemplate="header">
      <tr>
        <th>#</th>
        <th pSortableColumn="id">Order ID <p-sortIcon field="id"></p-sortIcon></th>
        <th pSortableColumn="tracking_number">Tracking Number <p-sortIcon field="tracking_number"></p-sortIcon></th>
        <th pSortableColumn="order_tracking.status">Status <p-sortIcon field="order_tracking.status"></p-sortIcon></th>
        <th>Progress</th>
        <th pSortableColumn="order_tracking.carrier">Carrier <p-sortIcon field="order_tracking.carrier"></p-sortIcon></th>
        <th pSortableColumn="order_tracking.last_tracking_status?.eta">ETA <p-sortIcon field="order_tracking.last_tracking_status?.eta"></p-sortIcon></th>
        <th pSortableColumn="order_tracking.modified_time">Modified Time <p-sortIcon field="order_tracking.modified_time"></p-sortIcon></th>
        <th style="text-align: center">Action</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
      <tr class="data-row">
        <td>{{ rowIndex + 1 }}</td>
        <td>{{ i.id }}</td>
        <td>{{ i.tracking_number }}</td>
        <td>
          <span [ngSwitch]="i.order_tracking.status | uppercase">
            <p-tag *ngSwitchCase="'PROCESSING'" severity="info" icon="pi pi-cog" [value]="i.order_tracking.status"></p-tag>
            <p-tag *ngSwitchCase="'PRE_TRANSIT'" severity="info" icon="pi pi-cart-arrow-down" [value]="i.order_tracking.status"></p-tag>
            <p-tag *ngSwitchCase="'SHIPPED'" severity="info" icon="pi pi-truck" [value]="i.order_tracking.status"></p-tag>
            <p-tag *ngSwitchCase="'TRANSIT'" severity="info" icon="pi pi-truck" [value]="i.order_tracking.status"></p-tag>
            <p-tag *ngSwitchCase="'OUT FOR DELIVERY'" severity="info" icon="pi pi-map-marker" [value]="i.order_tracking.status"></p-tag>
            <p-tag *ngSwitchCase="'DELIVERED'" severity="success" icon="pi pi-check" [value]="i.order_tracking.status"></p-tag>
            <p-tag *ngSwitchCase="'UNKNOWN'" severity="danger" icon="pi pi-flag-fill" [value]="i.order_tracking.status"></p-tag>
            <p-tag *ngSwitchDefault severity="warning" icon="pi pi-ban" [value]="i.order_tracking.status"></p-tag>
          </span>
        </td>
        <td style="width: 250px">
          <p-progressSpinner *ngIf="!i.progress_percent" styleClass="w-2rem h-1rem" strokeWidth="2" fill="var(--surface-ground)" animationDuration="0.5s" />
          <p-progressBar
            *ngIf="i.progress_percent"
            [value]="i.progress_percent"
            [style]="{ width: '100%', cursor: 'pointer' }"
            pTooltip="Show Tracking Details"
            tooltipPosition="top"
            [ngClass]="getProgressColorClass(i.progress_percent)"
            (click)="onShowTrackingDetail(i)"
            >>
          </p-progressBar>
        </td>
        <td>{{ i.order_tracking.carrier | uppercase }}</td>
        <td>{{ i.order_tracking.last_tracking_status?.eta | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td>{{ i.order_tracking.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td>
          <button
            pButton
            type="button"
            pTooltip="Show Tracking Details"
            tooltipPosition="left"
            icon="pi pi-list-check"
            class="p-button-rounded small-button"
            (click)="onShowTrackingDetail(i)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-paginator
    #paginator
    [rows]="30"
    [totalRecords]="totalOrderTracking"
    [rowsPerPageOptions]="[30, 50, 100]"
    [showFirstLastIcon]="true"
    (onPageChange)="onPageChange($event)"
    currentPageReportTemplate="Showing {first} to {last} of {{ totalOrderTracking }}"
    [showCurrentPageReport]="true"
  ></p-paginator>
</div>

<p-dialog header="Tracking Details" [modal]="true" [(visible)]="displayTrackingDetailDialog" [style]="{ width: '90%', height: '100%' }">
  <div class="grid">
    <div class="col-3">
      <p-card *ngIf="tracking_status">
        <ng-template pTemplate="header">
          <strong class="ml-2">{{ tracking_status.trackingNumber }}</strong>
        </ng-template>
        <ng-template pTemplate="content">
          <p>
            <strong>Status: </strong>
            <ng-container *ngIf="tracking_status?.trackingStatus as i">
              <span [ngSwitch]="i.status | uppercase">
                <p-tag *ngSwitchCase="'PROCESSING'" severity="info" icon="pi pi-cog" [value]="i.status"></p-tag>
                <p-tag *ngSwitchCase="'PRE_TRANSIT'" severity="info" icon="pi pi-cart-arrow-down" [value]="i.status"></p-tag>
                <p-tag *ngSwitchCase="'SHIPPED'" severity="info" icon="pi pi-truck" [value]="i.status"></p-tag>
                <p-tag *ngSwitchCase="'TRANSIT'" severity="info" icon="pi pi-truck" [value]="i.status"></p-tag>
                <p-tag *ngSwitchCase="'OUT FOR DELIVERY'" severity="info" icon="pi pi-map-marker" [value]="i.status"></p-tag>
                <p-tag *ngSwitchCase="'DELIVERED'" severity="success" icon="pi pi-check" [value]="i.status"></p-tag>
                <p-tag *ngSwitchCase="'UNKNOWN'" severity="danger" icon="pi pi-flag-fill" [value]="i.status"></p-tag>
                <p-tag *ngSwitchDefault severity="warning" icon="pi pi-ban" [value]="i.status"></p-tag>
              </span>
            </ng-container>
          </p>
          <strong>Status Time: </strong> {{ tracking_status.trackingStatus?.statusDate | date : "MMM dd, yyyy hh:mm:ss a" }}
          <p><strong>Status Details:</strong> {{ tracking_status.trackingStatus?.statusDetails }}</p>
          <p><strong>Carrier:</strong> {{ tracking_status.carrier | uppercase }}</p>
          <p><strong>Service Level:</strong> {{ tracking_status.servicelevel?.name }}</p>
          <p><strong>ETA:</strong> {{ tracking_status?.eta | date : "short" }}</p>
          <p>
            <strong>Progress:</strong>
            <p-progressBar
              *ngIf="tracking_status.progress_percent"
              [value]="tracking_status.progress_percent"
              [style]="{ width: '100%' }"
              pTooltip="Show Tracking Details"
              tooltipPosition="top"
              [ngClass]="getProgressColorClass(tracking_status.progress_percent)"
              >>
            </p-progressBar>
          </p>
          <p>
            <strong>Address From:</strong> {{ tracking_status.addressFrom?.city }}, {{ tracking_status.addressFrom?.state }}
            {{ tracking_status.addressFrom?.zip }}
          </p>
          <p><strong>Address To:</strong> {{ tracking_status.addressTo?.city }}, {{ tracking_status.addressTo?.state }} {{ tracking_status.addressTo?.zip }}</p>
        </ng-template>
      </p-card>
    </div>

    <div class="col-9">
      <p-card>
        <ng-template pTemplate="header">
          <strong class="ml-2">Tracking History</strong>
        </ng-template>
        <p-table [value]="tracking_status?.trackingHistory">
          <ng-template pTemplate="header">
            <tr>
              <th>Date</th>
              <th>Location</th>
              <th>Status</th>
              <th>Details</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-history>
            <tr class="data-row">
              <td class="no-wrap">{{ history.statusDate | date : "short" }}</td>
              <td>{{ history.location?.city }}, {{ history.location?.state }} {{ history.location?.zip }}</td>
              <td>{{ history.substatus?.text }}</td>
              <td>{{ history.statusDetails }}</td>
            </tr>
          </ng-template>
        </p-table>
      </p-card>
    </div>
  </div>
</p-dialog>
