import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ConversationService } from '../services/conversation.service';
import { Observable } from 'rxjs';
import { Conversation } from '../models/conversation.model';

@Injectable({
  providedIn: 'root',
})
export class QualityControlService {
  private apiUrl = `${environment.apiUrl}`;

  constructor(private http: HttpClient, private conversationService: ConversationService) {}

  getOrderDetail(id: number) {
    return this.http.get(`${this.apiUrl}/qc/orderDetail?id=${id}`);
  }

  shipOrder(orderId: number, verified_by: string) {
    return this.http.put(`${this.apiUrl}/qc/shipOrder?orderId=${orderId}&verified_by=${verified_by}`, {});
  }

  countConversationComment(id: number) {
    return this.http.get(`${this.apiUrl}/qc/countConversationComment?orderId=${id}`);
  }

  getSellerDetail(userId: number) {
    return this.http.get(`${this.apiUrl}/qc/getUserDetail?userId=${userId}`);
  }

  getStaffDetail(staffName: string) {
    return this.http.get(`${this.apiUrl}/qc/getUserDetail?verified_by=${staffName}`);
  }

  getConversation(orderId: any): Observable<Conversation> {
    return this.conversationService.findConversationByOrderId({ orderId: orderId });
  }
}
