import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { Comment, CommentQuery, Conversation, ConversationDashboardQuery, ConversationQuery } from '../models/conversation.model';

@Injectable({
  providedIn: 'root',
})
export class ConversationService {
  private apiUrl = `${environment.apiUrl}/conversation`;

  constructor(private http: HttpClient) {
  }

  findConversationByOrderId(query: ConversationQuery = {}): Observable<Conversation> {
    const params = Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&');
    return this.http.get(`${this.apiUrl}/findConversationByOrderId?${params}`).pipe(
      map((response: any) => response),
    );
  }

  findConversations(query: ConversationDashboardQuery): Observable<{ total: number; data: Conversation[] }> {
    const params = Object.entries(query).map(([key, value]) => `${key}=${value}`).join('&');
    return this.http.get(`${this.apiUrl}/findConversations?${params}`).pipe(
      map((response: any) => response),
    );
  }

  updateConversationStatus(query: ConversationQuery): Observable<Conversation> {
    return this.http.post(`${this.apiUrl}/updateConversationStatus`, query).pipe(
      map((response: any) => response),
    );
  }

  addComment(query: CommentQuery): Observable<Comment> {
    return this.http.post(`${this.apiUrl}/addComment`, query).pipe(
      map((response: any) => response),
    );
  }
}
