<app-cards [overallStatistics]="overallStatistics" [cards]="cards"></app-cards>
<div class="grid">
  <div class="col-12 xl:col-6">
    <p-card styleClass="h-full">
      <ng-template pTemplate="header">
        <div class="flex w-full justify-content-between align-items-center px-3 pt-3">
          <span class="text-2xl">Order ({{menuState.label}})</span>
          <p-menu #menu aria-hidden="true" [model]="menuItem" [popup]="true" />
          <p-button class="text-xl" label="Show" icon="pi pi-search" [text]="true" (onClick)="menu.toggle($event)" />
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="h-full">
          <app-line-charts [chartConfigs]="chartConfigs" [dataPoints]="dataPoints" [status]="menuState.status"
            (onSelectChart)="onSelectChart($event)"></app-line-charts>
        </div>
      </ng-template>
    </p-card>
  </div>
  <div class="col-12 xl:col-6">
    <p-card styleClass="h-full">
      <ng-template pTemplate="header">
        <div class="flex w-full justify-content-between align-items-center px-3 pt-3">
          <span class="text-2xl">Seller Order ({{menuState.label}} - {{totalSellerOrders}})</span>
          <p-menu #menu [model]="menuItem" [popup]="true" />
          <p-button class="text-xl" label="Show" icon="pi pi-search" [text]="true" (onClick)="menu.toggle($event)" />
        </div>
      </ng-template>
      <ng-template pTemplate="content">
        <div class="overflow-y-auto" style="height: 635px;">
          @for (value of values; track value[0].label; let idx = $index; let last = $last) {
          <p-meterGroup [value]="value">
            <ng-template pTemplate="label">
              <div class="flex flex-wrap gap-3 pb-5">
                <ng-container *ngFor="let meterItem of value; let index = index">
                  <div class="flex justify-content-between gap-5">
                    <div class="flex flex-column gap-1">
                      <span class="font-bold text-lg">{{ meterItem.label }}</span>
                      <span class="text-secondary text-sm">{{ meterItem.value }}%
                        ({{ meterItem.actual }}/{{totalSellerOrders}})</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </p-meterGroup>
          }
        </div>
      </ng-template>
    </p-card>
  </div>
</div>