import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  selector: 'app-product-toolbar',
  standalone: true,
  imports: [TabMenuModule],
  templateUrl: './product-toolbar.component.html',
  styleUrl: './product-toolbar.component.css',
})
export class ProductToolbarComponent {
  items: MenuItem[] = [];
  constructor(private router: Router) {}

  ngOnInit() {
    this.items = [
      { label: 'Products', icon: 'pi pi-fw pi-warehouse', routerLink: '/product/products' },
      { label: 'Variants', icon: 'pi pi-fw pi-calendar', routerLink: '/product/variants' },
      { label: 'Pricing Tiers', icon: 'pi pi-database', routerLink: '/product/pricing-tiers' },
      { label: 'Pricing Rules', icon: 'pi pi-calculator', routerLink: '/product/pricing-rules' },
    ];
  }
}
