import { Component, OnInit } from '@angular/core';
import { UserManagementToolbarComponent } from "../user-management-toolbar/user-management-toolbar.component";
import { SharePrimeNGModule } from '../../share-primeng.module';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { UserManagementService } from '../user.management.service';
import { ConfirmationService, MessageService } from 'primeng/api';

@Component({
  selector: 'app-roles',
  standalone: true,
  imports: [UserManagementToolbarComponent, SharePrimeNGModule, ReactiveFormsModule],
  providers: [ConfirmationService, MessageService],
  templateUrl: './roles.component.html',
  styleUrl: './roles.component.css'
})
export class RolesComponent implements OnInit {
  displayDialog: boolean = false;
  roles = [];
  constructor(private fb: FormBuilder,
    private userService: UserManagementService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService) {
    this.getRoles();
  }

  ngOnInit(): void {

  }

  roleForm = this.fb.group({
    roleName: ['', [Validators.required]],
    displayName: ['', Validators.required],
    description: [],
    id: []
  }, {})

  get roleName() {
    return this.roleForm.controls['roleName'];
  }

  get displayName() {
    return this.roleForm.controls['displayName'];
  }

  get description() {
    return this.roleForm.controls['description'];
  }

  openDialog() {
    this.roleForm.setValue({ roleName: '', displayName: '', description: null, id: null });
    this.displayDialog = true;
  }

  closeDialog() {
    this.displayDialog = false;
  }

  save() {
    console.log(this.roleForm.value)
    this.userService.saveRole(this.roleForm.value).subscribe({
      next: (response) => {
        this.displayDialog = false;
        this.getRoles();
      },
      error: (err: any) => {
        this.messageService.add({
          severity: 'error',
          summary: err.error,
          detail: err.messages,
          sticky: true,
        });
      }
    })
  }

  getRoles() {
    this.userService.getRoles().subscribe({
      next: (response: any) => {
        this.roles = response;
      },
      error: (e) => {

      }
    });
  }

  openRoleEdit(role: any) {
    console.log(role);
    this.roleForm.setValue({ roleName: role.name, displayName: role.displayName, description: role.description, id: role.id });
    this.displayDialog = true;
  }

  openDeleteDialog(role: any) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this role?',
      accept: () => {
        this.userService.deleteRole(role.id).subscribe({
          next: (response) => {
            this.getRoles();
          },
          error: (err: any) => {
            this.messageService.add({
              severity: 'error',
              summary: err.error,
              detail: err.messages,
              sticky: true,
            });
          }
        })
      }
    });
  }
}

