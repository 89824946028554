<app-product-toolbar></app-product-toolbar>
<br />
<p-toolbar>
  <div class="p-toolbar-group-start"></div>
  <div class="p-toolbar-group-center">
    <strong class="mr-2">Style: </strong>
    <p-dropdown [options]="optionStyles" [(ngModel)]="selectedSearch.style" (onChange)="onSearch()" [style]="{ width: '200px' }" />
    <strong class="mr-2 ml-2">Size: </strong>
    <p-dropdown [options]="optionSizes" [(ngModel)]="selectedSearch.size" (onChange)="onSearch()" [style]="{ width: '200px' }" />
    <div class="p-field-row">
      <p-button icon="pi pi-search-plus" label="Search" severity="success" class="p-button-success mr-2 ml-2" (click)="onSearch()" />
      <p-button icon="pi pi-eraser" label="Clear" severity="success" (click)="onClearSearch()" class="p-button-success" class="mr-2" />
    </div>
  </div>
  <div class="p-toolbar-group-end"></div>
</p-toolbar>
<p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '3px' }" />
<div class="card">
  <p-table [value]="pricingRules" dataKey="id" [scrollable]="true" scrollHeight="flex">
    <!-- Table Header -->
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="id" style="text-align: center">ID</th>
        <th pSortableColumn="style">Style <p-sortIcon field="style"></p-sortIcon></th>
        <th pSortableColumn="size">Size <p-sortIcon field="size"></p-sortIcon></th>
        <th pSortableColumn="basecost">Base Cost</th>
        <th pSortableColumn="price_2nd_side">Price 2nd Side</th>
        <th pSortableColumn="price_sleeve">Price Sleeve</th>
        <th pSortableColumn="price_shipping">Price Shipping</th>
        <th pSortableColumn="price_shipping_2nd_item">Price Shipping 2nd Item</th>
        <th pSortableColumn="created_time">Created Time</th>
        <th pSortableColumn="modified_time">Modified Time</th>
      </tr>
    </ng-template>

    <!-- Table Body -->
    <ng-template pTemplate="body" let-rule>
      <tr>
        <td style="text-align: right">{{ rule.id }}</td>
        <td>{{ rule.style }}</td>
        <td>{{ rule.size }}</td>
        <td>${{ rule.basecost?.toFixed(2) }}</td>
        <td>${{ rule.price_2nd_side?.toFixed(2) }}</td>
        <td>${{ rule.price_sleeve?.toFixed(2) }}</td>
        <td>${{ rule.price_shipping?.toFixed(2) }}</td>
        <td>${{ rule.price_shipping_2nd_item?.toFixed(2) }}</td>
        <td>{{ rule.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
        <td>{{ rule.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
      </tr>
    </ng-template>
  </p-table>
</div>
<p-toast></p-toast>
