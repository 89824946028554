<p-card>
  <div class="card">
    <p-progressBar *ngIf="!tableData" mode="indeterminate" [style]="{ height: '3px' }" />
    <p-table [value]="tableData" [scrollable]="true" scrollHeight="flex">
      <ng-template pTemplate="header">
        <tr>
          <th *ngFor="let header of tableHeaders; let i = index" [ngClass]="{ 'align-right': i >= 2 }">
            {{ header }}
          </th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowData>
        <tr class="data-row">
          <td *ngFor="let col of rowData; let i = index" [ngClass]="{ 'align-right': i >= 2 }">
            {{ col }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</p-card>
