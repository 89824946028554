import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: 'root' })
export class RegisterService {
    private apiUrl = `${environment.apiUrl}/auth`;
    constructor(private http: HttpClient) {}

    signUp(user: any){
        const url = `${this.apiUrl}/signUp`;
        return this.http.post(url,user)
    }

}