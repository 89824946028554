<p-tabView>
  <p-tabPanel *ngFor="let category of settingCategories" [header]="category.description">
    <p-table [value]="category.settings" class="p-datatable-sm">
      <ng-template pTemplate="header">
        <tr>
          <th>#</th>
          <th pSortableColumn="key">Key <p-sortIcon field="key"></p-sortIcon></th>
          <th pSortableColumn="description">Description <p-sortIcon field="description"></p-sortIcon></th>
          <th pSortableColumn="value">Value <p-sortIcon field="value"></p-sortIcon></th>
          <th pSortableColumn="created_time">Created Time <p-sortIcon field="created_time"></p-sortIcon></th>
          <th pSortableColumn="modified_time">Modified Time <p-sortIcon field="modified_time"></p-sortIcon></th>
          <th style="width: 110px">Action</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i let-rowIndex="rowIndex">
        <tr class="data-row">
          <td>{{ rowIndex + 1 }}</td>
          <td>{{ i.key }}</td>
          <td>{{ i.description }}</td>
          <td>
            <input
              *ngIf="!i.isEditMode"
              pInputText
              class="value-disabled custom-tooltip"
              tooltipStyleClass="custom-tooltip"
              [value]="i.value"
              [pTooltip]="i.value"
              tooltipPosition="top"
              disabled
            />
            <input *ngIf="i.isEditMode" pInputText [(ngModel)]="i.value" class="value-editable" />
          </td>
          <td>{{ i.created_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
          <td>{{ i.modified_time | date : "MMM dd, yyyy hh:mm:ss a" }}</td>
          <td>
            <button
              pButton
              type="button"
              icon="pi pi-pencil"
              *ngIf="!i.isEditMode"
              pTooltip="Update"
              tooltipPosition="top"
              (click)="enableSetting(i)"
              class="small-button p-button-rounded p-button-success"
            ></button>
            <button
              pButton
              icon="pi pi-check"
              *ngIf="i.isEditMode"
              pTooltip="Save Change"
              tooltipPosition="top"
              (click)="saveSetting(i)"
              class="small-button p-button-rounded p-button-success"
              style="background: green"
            ></button>
            <button
              pButton
              type="button"
              icon="pi pi-times"
              *ngIf="i.isEditMode"
              pTooltip="Cancel"
              tooltipPosition="top"
              (click)="cancelEditSetting(i)"
              class="small-button p-button-rounded p-button-success"
              style="background: red"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-tabPanel>
</p-tabView>
<p-confirmDialog [style]="{ width: '30vw' }"></p-confirmDialog>
