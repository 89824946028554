import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharePrimeNGModule } from '../share-primeng.module';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AuditService } from '../services/audit.service';
import { AuditTrailQuery } from '../models/audit.model';
import { combineLatest, finalize } from 'rxjs';

@Component({
  selector: 'app-audit-trail',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharePrimeNGModule],
  providers: [ConfirmationService, MessageService],
  templateUrl: './audit-trail.component.html',
  styleUrl: './audit-trail.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AuditTrailComponent {
  auditTrails: any = {};
  searchOptions: any = {
    Actions: [],
    Entities: [],
    Users: [],
  };
  selectedSearch = {
    action: '',
    entity: '',
    user: '',
  };
  defaultPageLimit: number = 30;
  isLoading = false;

  constructor(private auditService: AuditService, private confirmationService: ConfirmationService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.getActionEntity();
    this.onSearch();
  }

  getActionEntity() {
    combineLatest([this.auditService.getAuditActions(), this.auditService.getAuditEntities(), this.auditService.getAuditUsers()]).subscribe(
      ([actions, entities, users]: any) => {
        this.searchOptions.Actions = actions;
        this.searchOptions.Entities = entities;
        this.searchOptions.Users = users;
      }
    );
  }

  onSearch(
    query: AuditTrailQuery = {
      offset: 0,
      limit: this.defaultPageLimit,
      action: this.selectedSearch.action,
      entityName: this.selectedSearch.entity,
      created_by: this.selectedSearch.user,
    }
  ) {
    this.isLoading = true;
    this.auditService.findAuditTrails(query)
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe({
      next: ({ total, data }) => {
        this.auditTrails = {
          total,
          data: data.map((item) => ({
            ...item,
            newValue: JSON.stringify(item.newValue),
          })),
        };
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: `Unable to load audit trails: ${err}`,
        });
      },
    });
  }

  onClearSearch() {
    this.selectedSearch = {
      action: '',
      entity: '',
      user: '',
    };

    this.onSearch();
  }

  onPageChangeAuditTrail(event: any) {
    this.onSearch({ offset: event.page * event.rows, limit: event.rows });
  }
}
