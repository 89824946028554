import { Component } from '@angular/core';
import { UserManagementToolbarComponent } from '../user-management-toolbar/user-management-toolbar.component';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { UserManagementService } from '../user.management.service';
import { PermissionDto } from '../../models/user.permission.model';
import { MessageService } from 'primeng/api';
import _ from 'underscore';

@Component({
  selector: 'app-permissions',
  standalone: true,
  providers: [MessageService],
  imports: [UserManagementToolbarComponent, SharePrimeNGModule],
  templateUrl: './permissions.component.html',
  styleUrl: './permissions.component.css',
})
export class PermissionsComponent {
  constructor(private userService: UserManagementService, private messageService: MessageService) {
    this.getUserRolePermissions();
  }

  roles: any[] = [];

  permissions: any[] = [];

  rolePermissionMap: { [roleId: string]: { [permissionId: string]: boolean } } = {};

  checkAll: { [roleId: string]: boolean } = {};

  indeterminate: { [roleId: string]: boolean } = {};

  onCheckboxChange(role: any, feature: string, checked: boolean) {
    role.features[feature] = checked;
  }

  navigate() {}

  toggleCheckAll(roleName: string) {
    const checked = this.checkAll[roleName];
    this.permissions.forEach((permission) => {
      this.rolePermissionMap[roleName][permission.name] = checked;
    });
  }

  getUserRolePermissions() {
    this.userService.getUserRolePermissions().subscribe({
      next: (response: any) => {
        this.roles = response.roles;
        this.permissions = response.permissions.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name));
        this.roles.forEach((role) => {
          this.rolePermissionMap[role.name] = {};
          this.permissions.forEach((permission) => {
            // Set default value or fetch from a data source
            const permissionId = permission.id;
            const permissionInRole = _.filter(role.permissions, (permission) => {
              return permission.id == permissionId;
            });
            this.rolePermissionMap[role.name][permission.name] = permissionInRole?.length > 0;
            this.updateIndeterminateState(role.name);
          });
        });
        console.log(this.rolePermissionMap);
      },
      error: (error) => {},
    });
  }

  updateIndeterminateState(roleName: string) {
    const permissions = this.rolePermissionMap[roleName];
    const values = Object.values(permissions);
    const checkedCount = values.filter((v) => v).length;

    if (checkedCount === values.length) {
      this.checkAll[roleName] = true;
      this.indeterminate[roleName] = false;
    } else if (checkedCount > 0) {
      this.checkAll[roleName] = false;
      this.indeterminate[roleName] = true;
    } else {
      this.checkAll[roleName] = false;
      this.indeterminate[roleName] = false;
    }
  }

  onPermissionChange(roleName: string) {
    this.updateIndeterminateState(roleName);
  }

  savePermission() {
    this.userService.saveUserRolePermissions(this.rolePermissionMap).subscribe({
      next: (response: any) => {
        this.messageService.add({
          severity: 'success',
          summary: 'Success',
          detail: 'User Permission has been updated successfully',
          sticky: true,
        });
      },
      error: (e) => {
        const message = e.error.message;
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: message,
          sticky: true,
        });
      },
    });
  }
}
