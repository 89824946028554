import { Component, Input } from '@angular/core';
import { SharePrimeNGModule } from '../../../share-primeng.module';

@Component({
  selector: 'app-timeline',
  standalone: true,
  imports: [
    SharePrimeNGModule
  ],
  templateUrl: './timeline.component.html',
  styleUrl: './timeline.component.css'
})
export class TimelineComponent {
  @Input()
  timelines: any[] | undefined;
}
