import { Component, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { AuthenticationService } from '../../services/authentication.service';
import { merge, of } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qclayout',
  standalone: true,
  imports: [CommonModule, SharePrimeNGModule],
  templateUrl: './qclayout.component.html',
  styleUrl: './qclayout.component.css',
  host: { ngSkipHydration: 'true' },
})
export class QCLayoutComponent {
  isLogged = signal(false);

  topNavMenu = [
    {
      label: 'HOME',
      url: '',
    },
  ];

  topLeftNavMenu = [
    {
      label: 'Switch User',
      url: 'login',
    },
  ];

  topLefNavMenuLogged = [
    {
      label: 'Welcome: <username>',
      url: '#',
    },
    {
      label: 'Switch User',
      url: 'login',
    },
  ];

  constructor(private authenticationService: AuthenticationService, private router: Router) {
    merge(this.authenticationService.user, of(this.authenticationService.userSession)).subscribe((user: any) => {
      this.isLogged.set(!!user);
      if (!user) {
        return;
      }

      const [welcome, logout] = this.topLefNavMenuLogged;
      this.topLeftNavMenu = [
        { ...welcome, label: welcome.label.replace('<username>', `${user.userName} (${user.roles?.join(', ')})`) },
        logout,
      ];
    });
  }

  onClick(url: any){
    if(url){
      this.router.navigate([url],{queryParams: {returnUrl : this.router.url}})
    }
  }
}
