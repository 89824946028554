import { CommonModule } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { LeftMenuComponent } from '../../shared-ui/left-menu/left-menu.component';
import { WalletToolbarComponent } from '../../shared-ui/wallet-toolbar/wallet-toolbar.component';
import { WalletService } from '../../services/wallet.service';
import { TransactionStatus, WalletTransaction, WalletUserBalance } from '../../models/wallet.model';
import { FormsModule } from '@angular/forms';
import { AuthenticationService } from '../../services/authentication.service';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { MessageService } from 'primeng/api';
import { finalize } from 'rxjs/operators';

interface Filter {
  userName: string;
  dateRange: any | null;
  maxDate: Date;
}

@Component({
  selector: 'app-wallet-dashboard',
  standalone: true,
  imports: [CommonModule, FormsModule, SharePrimeNGModule, LeftMenuComponent, WalletToolbarComponent],
  providers: [MessageService],
  templateUrl: './wallet-dashboard.component.html',
  styleUrl: './wallet-dashboard.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WalletDashboardComponent {
  transactions: WalletTransaction[] = [];
  userBalance: WalletUserBalance[] = [];
  totalBalance: number = 0;
  totalTransactions: number = 0;
  filters: Filter = {
    userName: '',
    dateRange: [],
    maxDate: new Date(),
  };
  userName: any;
  isLoading = false;

  constructor(private walletService: WalletService, private authenticationService: AuthenticationService, private messageService: MessageService) {
    authenticationService.user.subscribe((user) => {
      this.userName = user;
    });
    this.userName = this.authenticationService.userSession;
  }

  ngOnInit(): void {
    this.getAllUserBalance();
    this.getTransactions();
  }

  onPageChange(event: any) {
    this.getTransactions(event.page * event.rows, event.rows);
  }

  getTransactions(offset: number = 0, limit: number = 20) {
    this.isLoading = true;
    const userName = this.filters.userName;
    let startDate = '';
    let endDate = '';
    if (this.filters.dateRange) {
      startDate = this.filters.dateRange[0] ? this.filters.dateRange[0].toISOString().split('T')[0] : '';
      endDate = this.filters.dateRange[1] ? this.filters.dateRange[1].toISOString().split('T')[0] : '';
    }

    this.walletService
      .findTransactions(offset, limit, userName, startDate, endDate, 'fund')
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (response) => {
          this.transactions = response.data.sort((a, b) => b.id - a.id);
          this.totalTransactions = response.total;
        },
        error: (error) => {
          this.sendError('Load wallet transactions!', `Unable to perform action: ${error}`);
        },
      });
  }

  getAllUserBalance() {
    this.walletService.findAllUserBalance().subscribe({
      next: (response) => {
        this.userBalance = response.sort((a, b) => b.balance - a.balance);
        this.totalBalance = this.userBalance.reduce((sum, userBalance) => sum + userBalance.balance, 0);
      },
      error: (error) => {
        this.sendError('Load wallet balance!', `Unable to perform action: ${error}`);
      },
    });
  }

  updateTransaction(updatedTransaction: WalletTransaction): void {
    const index = this.transactions.findIndex((t) => t.id === updatedTransaction.id);
    if (index !== -1) {
      this.transactions[index] = updatedTransaction;
    }
  }

  approveTransaction(t: WalletTransaction): void {
    const tCopy = { ...t };
    tCopy.status = TransactionStatus.APPROVED;
    this.walletService.updateTransaction(tCopy).subscribe({
      next: (tUpdated) => {
        this.sendInfo('Approve Transaction!', 'The transaction has been approved successfully!');
        this.getAllUserBalance();
        this.updateTransaction(tUpdated);
      },
      error: (error) => {
        this.sendError('Approve Transaction!', `Unable to perform action: ${error}`);
      },
    });
  }

  rejectTransaction(t: WalletTransaction): void {
    const tCopy = { ...t };
    tCopy.status = TransactionStatus.REJECTED;
    this.walletService.updateTransaction(tCopy).subscribe({
      next: (tUpdated) => {
        this.sendInfo('Reject Transaction!', 'The transaction has been rejected successfully!');
        this.getAllUserBalance();
        this.updateTransaction(tUpdated);
      },
      error: (error) => {
        this.sendError('Reject Transaction!', `Unable to perform action: ${error.messages}`);
      },
    });
  }

  onSearch() {
    this.getTransactions();
  }

  onClear() {
    this.filters.userName = '';
    this.filters.dateRange = [];
    this.getTransactions();
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }

  sendInfo(summary: string, detail: string) {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }
}
