import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, CUSTOM_ELEMENTS_SCHEMA, ViewChild } from '@angular/core';

import { EditorModule } from 'primeng/editor';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { AuthenticationService } from '../../services/authentication.service';
import { Conversation, ConversationDashboardQuery } from '../../models/conversation.model';
import { MessageService } from 'primeng/api';
import { ActivatedRoute } from '@angular/router';
import { ConversationService } from '../../services/conversation.service';
import { combineLatest, finalize, timer } from 'rxjs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SettingService } from '../../services/setting.service';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-conversation-dashboard',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharePrimeNGModule, EditorModule],
  providers: [MessageService],
  templateUrl: './conversation-dashboard.component.html',
  styleUrl: './conversation-dashboard.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ConversationDashboardComponent {
  @ViewChild('paginator') paginator!: Paginator;

  searchForm: FormGroup;
  orderStatuses: any[] = [];
  convStatuses: any[] = [];

  conversations: Conversation[] = [];
  dashboardConversations: Conversation[] = [];
  totalConversations: number = 0;
  defaultPageLimit: number = 30;
  activeIndex: number = 0;
  loginUserName: any;
  isLoading = false;

  constructor(
    private authenticationService: AuthenticationService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    private conversationService: ConversationService,
    private settingService: SettingService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder
  ) {
    authenticationService.user.subscribe((user) => {
      this.loginUserName = user;
    });
    this.loginUserName = this.authenticationService.userSession?.userName;

    this.searchForm = this.fb.group({
      orderId: '',
      orderStatus: '',
      convId: [''],
      convStatus: [''],
      userName: '',
    });
  }

  ngOnInit(): void {
    this.loadAllSettings();
    this.openConversation();
    this.loadConversationList();
  }

  loadAllSettings() {
    combineLatest([this.settingService.getSetting('ORDER_STATUS'), this.settingService.getSetting('CONVERSATION_STATUS')]).subscribe(
      ([orderStatuses, convstatuses]: any) => {
        this.orderStatuses = orderStatuses.map((s: any) => ({
          label: s,
          value: s,
        }));
        this.convStatuses = convstatuses.map((s: any) => ({
          label: s,
          value: s,
        }));
      }
    );
  }

  loadConversationList(
    query: ConversationDashboardQuery = {
      offset: 0,
      limit: this.defaultPageLimit,
    }
  ) {
    this.isLoading = true;
    this.conversationService.findConversations(query)
    .pipe(
      finalize(() => {
        this.isLoading = false;
      })
    )
    .subscribe({
      next: ({ total, data }) => {
        this.totalConversations = total;
        this.dashboardConversations = data;
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: `Unable to load data for the conversation dashboard: ${err}`,
        });
      },
    });
  }

  openConversation(openOrderId: string | null = '') {
    if (!openOrderId) {
      this.route.paramMap.subscribe((params) => (openOrderId = params.get('id')));
    }

    if (!openOrderId) {
      return;
    }

    this.conversationService.findConversationByOrderId({ orderId: openOrderId }).subscribe({
      next: (conversation) => {
        conversation.comments = conversation.comments || []; // Initialize comments list for 1st time
        this.conversations.push(conversation);
        timer(0).subscribe(() => {
          // Add delay to ensure UI loaded before changing tab
          this.activeIndex = this.conversations.length;
          this.cdr.detectChanges();
        });
      },
      error: (err) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Error!',
          detail: `Unable to open the conversation: ${err}`,
        });
      },
    });
  }

  resolveConversation(c: Conversation) {
    this.conversationService
      .updateConversationStatus({
        id: c.id,
        status: 'Resolved',
      })
      .subscribe({
        next: (conversation) => {
          c.status = conversation.status;
          c.modified_time = conversation.modified_time;
          this.messageService.add({
            severity: 'success',
            summary: 'Successfully!',
            detail: `This conversation has been resolved`,
          });
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error!',
            detail: `Unable to resolve the conversation: ${err}`,
          });
        },
      });
  }

  sendNewConversationComment(c: Conversation) {
    if (!c.editorContent) {
      return;
    }

    const sizeInBytes = this.calculateContentSize(c.editorContent);
    if (sizeInBytes > 612 * 1024) {
      // Add 100k for HTML overhead
      this.messageService.add({
        severity: 'error',
        summary: 'Error!',
        detail: 'Your comment could not be posted because it exceeds the 512kB limit.',
        life: 10000,
      });

      console.log(sizeInBytes / 1024);

      return;
    }

    // Update status to InProgress when there is more than 1 Comment and status is New
    if (c.status === 'New' && c.comments?.length > 0) {
      this.conversationService
        .updateConversationStatus({
          id: c.id,
          status: 'InProgress',
        })
        .subscribe({
          next: (conversation) => {
            c.status = conversation.status;
            c.modified_time = conversation.modified_time;
          },
          error: (err) => {
            this.messageService.add({
              severity: 'error',
              summary: 'Error!',
              detail: `Unable to change conversation status to InProgress: ${err}`,
            });
          },
        });
    }

    this.conversationService
      .addComment({
        id: c.id,
        first_comment: c.comments?.length === 0 ? this.extractTextFromHTML(c.editorContent) : '',
        last_comment: c.comments?.length > 0 ? this.extractTextFromHTML(c.editorContent) : '',
        comment: c.editorContent,
        created_by: this.loginUserName,
      })
      .subscribe({
        next: (addedComment) => {
          c.comments.push(addedComment);
        },
        error: (err) => {
          this.messageService.add({
            severity: 'error',
            summary: 'Error!',
            detail: `Failed to add comment due to an error: ${err}`,
            life: 10000,
          });
        },
      });

    c.editorContent = '';
  }

  calculateContentSize(content: string): number {
    const blob = new Blob([content], { type: 'text/html' });
    return blob.size;
  }

  scrollToBottom() {}

  onCloseThisTab(c: Conversation) {
    const index = this.conversations.findIndex((conversation) => conversation === c);
    if (index !== -1) {
      this.conversations.splice(index, 1);
      timer(0).subscribe(() => {
        // Add delay to ensure UI loaded before changing tab
        this.activeIndex = 0; // Open default Dashboard tab
        this.cdr.detectChanges();
        this.loadConversationList(); // Reload the dashboard
      });
    }
  }

  onTabClose(event: any) {
    const closedTab = event.index; // Get the index of the closed tab
    const con = this.conversations[closedTab - 1];
    this.onCloseThisTab(con);
  }

  onSearch(offset: number = 0, limit: number = this.defaultPageLimit) {
    const formValues = this.searchForm.value;
    const query: ConversationDashboardQuery = {
      offset: offset,
      limit: limit,
      orderId: formValues.orderId,
      orderStatus: formValues.orderStatus,
      id: formValues.convId,
      status: formValues.convStatus,
      userName: formValues.userName,
    };

    this.loadConversationList(query);
  }

  onPageChangeDashboard(event: any) {
    this.onSearch(event.page * event.rows, event.rows);
  }

  onClearSearch() {
    this.searchForm.reset();
    this.onSearch(0, this.paginator.rows);
  }

  extractTextFromHTML(html: string, maxLength: number = 150): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const textContent = doc.body.textContent || '';
    if (textContent.length > maxLength) {
      return textContent.slice(0, maxLength);
    }
    return textContent;
  }
}
