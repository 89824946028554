import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ProductToolbarComponent } from '../../shared-ui/product-toolbar/product-toolbar.component';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../services/product.service';
import { CommonModule } from '@angular/common';
import { Product } from '../../models/product.model';
import { SettingService } from '../../services/setting.service';
import { SharePrimeNGModule } from '../../share-primeng.module';
import { combineLatest } from 'rxjs';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-new-product',
  standalone: true,
  imports: [CommonModule, FormsModule, SharePrimeNGModule, ReactiveFormsModule, ProductToolbarComponent],
  providers: [ProductService, MessageService],
  templateUrl: './new-product.component.html',
  styleUrl: './new-product.component.css',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class NewProductComponent {
  mainForm: FormGroup;
  productId: string | null = null;
  formMode: string = 'new';
  styles: string[] = [];
  selectedItems: any[] = [];
  sizeList: any[] = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private productService: ProductService,
    private settingService: SettingService,
    private messageService: MessageService
  ) {
    this.mainForm = this.fb.group({
      brand: ['', [Validators.required, Validators.minLength(3)]],
      name: ['', [Validators.required]],
      style: [''],
      code: ['', [Validators.required]],
      color: [''],
      size: [''],
      sizeSelectedList: [[]],
      sizes: this.fb.array([]),
      colors: this.fb.array([]),
    });
  }

  ngOnInit(): void {
    this.loadAllSettings();
    this.route.paramMap.subscribe((params) => {
      this.productId = params.get('id');
      if (this.productId) {
        this.formMode = 'edit';
        this.mainForm.get('style')?.disable();
        this.loadProduct(this.productId);
      }
    });
  }
  get sizeSelectedList() {
    return this.mainForm.get('sizeSelectedList') as FormArray;
  }
  get brand() {
    return this.mainForm.get('brand');
  }
  get name() {
    return this.mainForm.get('name');
  }
  get code() {
    return this.mainForm.get('code');
  }
  get sizes(): FormArray {
    return this.mainForm.get('sizes') as FormArray;
  }
  get colors(): FormArray {
    return this.mainForm.get('colors') as FormArray;
  }

  addSize(): void {
    let sizeGroup;

    this.mainForm.value.sizeSelectedList.map((newSize: any) => {
      const findSize = this.mainForm.value.sizes.some((i: { size: any }) => i.size === newSize);
      if (!findSize) {
        sizeGroup = this.fb.group({ size: [newSize] });
        this.sizes.push(sizeGroup);
      }
    });
  }

  removeSize(index: number): void {
    this.sizes.removeAt(index);
  }

  addColor(): void {
    let colorGroup;
    const newColor = this.mainForm.value.color.trim();
    if (newColor) {
      const find = this.mainForm.value.colors.some((i: { color: any }) => i.color === newColor);
      if (!find) {
        colorGroup = this.fb.group({ color: [newColor] });
        this.colors.push(colorGroup);
      }
    }
    this.mainForm.get('color')?.reset();
  }

  removeColor(index: number): void {
    this.colors.removeAt(index);
  }

  createSize(size: string = ''): FormGroup {
    return this.fb.group({
      size: [size, Validators.required],
    });
  }

  createColor(color: string = ''): FormGroup {
    return this.fb.group({
      color: [color, Validators.required],
    });
  }

  onSubmit() {
    if (this.formMode === 'new') {
      this.createNewProduct();
    } else if (this.formMode === 'edit') {
      this.saveUpdatedProduct();
    }
  }

  createNewProduct() {
    if (this.mainForm.valid) {
      const productData = this.mainForm.value;

      // Remove last blank values in colors and sizes
      if (productData.sizes.at(productData.sizes.length - 1).size === '') {
        productData.sizes.splice(productData.sizes.length - 1, 1);
      }
      if (productData.colors.at(productData.colors.length - 1).color === '') {
        productData.colors.splice(productData.colors.length - 1, 1);
      }

      this.productService.createProduct(productData).subscribe({
        next: (response) => {
          this.sendInfo('Create product!', `Product has been created successfully`);
          this.router.navigate(['/product/products']);
        },
        error: (error) => {
          this.sendError('Create new product!', `Unable to perform action: ${error}`);
        },
      });
    }
  }

  saveUpdatedProduct() {
    if (this.mainForm.valid) {
      const productData = this.mainForm.value;
      productData.id = Number(this.productId);

      // Remove last blank values in colors and sizes
      if (productData.sizes.at(productData.sizes.length - 1).size === '') {
        productData.sizes.splice(productData.sizes.length - 1, 1);
      }
      if (productData.colors.at(productData.colors.length - 1).color === '') {
        productData.colors.splice(productData.colors.length - 1, 1);
      }

      this.productService.updateProduct(productData).subscribe({
        next: () => {
          this.sendInfo('Update product!', `Product has been updated successfully`);
          this.router.navigate(['/product/products']);
        },
        error: (error) => {
          this.sendError('Create new product!', `Unable to perform action: ${error}`);
        },
      });
    }
  }

  loadProduct(id: string) {
    this.productService.getProductById(id).subscribe({
      next: (product: Product) => {
        this.sizes.clear();
        product.sizes.forEach((size) => {
          this.sizes.push(this.createSize(size.size));
        });
        this.addSize();

        this.colors.clear();
        product.colors.forEach((color) => {
          this.colors.push(this.createColor(color.color));
        });
        this.addColor();

        return this.mainForm.patchValue(product);
      },
      error: (error) => {
        this.sendError('Load product list!', `Unable to perform action: ${error}`);
      },
    });
  }

  loadAllSettings() {
    combineLatest([this.settingService.getSetting('PRODUCT_STYLE'), this.settingService.getSetting('PRODUCT_SIZE')]).subscribe(([styles, sizes]: any) => {
      this.styles = styles;
      this.mainForm.get('style')?.setValue(this.styles[0]);

      this.sizeList = sizes.map((size: any) => ({
        label: size,
        value: size,
      }));
    });
  }

  navigate(url: string) {
    this.router.navigate([url]);
  }

  sendInfo(summary: string, detail: string) {
    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
    });
  }

  sendError(summary: string, detail: string) {
    this.messageService.add({
      severity: 'error',
      summary: summary,
      detail: detail,
    });
  }
}
