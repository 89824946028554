import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { TabMenuModule } from 'primeng/tabmenu';

@Component({
  selector: 'app-user-management-toolbar',
  standalone: true,
  imports: [TabMenuModule],
  templateUrl: './user-management-toolbar.component.html',
  styleUrl: './user-management-toolbar.component.css'
})
export class UserManagementToolbarComponent {
  items: MenuItem[] = [];
  constructor(private router: Router) {}

  ngOnInit() {
    this.items = [
      { label: 'Users', icon: 'pi pi-fw pi-user', routerLink: '/users' },
      { label: 'Roles', icon: 'pi pi-fw pi-user-plus', routerLink: '/roles' },
      { label: 'Permissions', icon: 'pi pi-fw pi-unlock', routerLink: '/permissions' }
    ];
  }
}
