<app-user-management-toolbar></app-user-management-toolbar>
<form [formGroup]="userForm">
  <div class="grid">
    <div class="col-4">
      <div class="field" style="display: grid">
        <label for="id">Role Name</label>
        <p-dropdown
          [style]="{ width: '66.6667%' }"
          id="id"
          [options]="roles"
          formControlName="roleId"
          optionLabel="name"
          optionValue="id"
          filterBy="name"
          [showClear]="true"
          placeholder="Select a Role"
        ></p-dropdown>
      </div>
      <div class="field" style="display: grid">
        <label for="status">Status</label>
        <p-dropdown
          id="status"
          [style]="{ width: '66.6667%' }"
          [options]="statuses"
          formControlName="status"
          optionLabel="name"
          optionValue="name"
          filterBy="name"
          [showClear]="true"
          placeholder="Select a status"
        ></p-dropdown>
      </div>
      <div class="field" style="display: grid">
        <label for="firstName">First Name</label>
        <input
          type="text"
          class="w-8 h-3rem border-round-md border-1 border-primary-400"
          pInputText
          formControlName="firstName"
          placeholder="Enter First Name"
        />
        <small *ngIf="userForm?.get('firstName')?.invalid && (userForm?.get('firstName')?.dirty || userForm?.get('firstName')?.touched)" class="block p-error">
          <div *ngIf="userForm?.get('firstName')?.errors?.['required']">Name is required.</div>
        </small>
      </div>
      <div class="field" style="display: grid">
        <label for="lastName">Last Name</label>
        <input type="text" class="w-8 h-3rem border-round-md border-1 border-primary-400" pInputText formControlName="lastName" placeholder="Enter Last Name" />
        <small *ngIf="userForm?.get('lastName')?.invalid && (userForm?.get('lastName')?.dirty || userForm?.get('lastName')?.touched)" class="block p-error">
          <div *ngIf="userForm?.get('lastName')?.errors?.['required']">Name is required.</div>
        </small>
      </div>
      <div class="field" style="display: grid">
        <label for="apiKey">API Key</label>
        <input
          type="text"
          class="w-8 h-3rem border-round-md border-1 border-primary-400"
          pInputText
          formControlName="apiKey"
          placeholder="The API key will be created automatically"
          readonly
        />
      </div>
    </div>
    <div class="col-4">
      <div class="field" style="display: grid">
        <label for="dateOfBirth">Date of Birth</label>
        <p-calendar
          formControlName="dateOfBirth"
          placeholder="Type your date of birth"
          readonlyInput="true"
          [inputStyle]="{ width: '364.438px' }"
          [yearRange]="yearRange"
          [yearNavigator]="true"
        ></p-calendar>
      </div>
      <div class="field" style="display: grid">
        <label for="phone">Phone</label>
        <input type="text" class="w-8 h-3rem border-round-md border-1 border-primary-400" pInputText formControlName="phone" placeholder="Phone" />
        <small *ngIf="userForm?.get('phone')?.invalid && (userForm?.get('phone')?.dirty || userForm?.get('phone')?.touched)" class="block p-error">
          <div *ngIf="userForm?.get('phone')?.errors?.['invalidPhone']">Phone should be valid</div>
        </small>
      </div>
      <div class="field" style="display: grid">
        <label for="address">Address</label>
        <input type="text" class="w-8 h-3rem border-round-md border-1 border-primary-400" pInputText formControlName="address" placeholder="Address" />
      </div>
      <div class="field" style="display: grid">
        <label for="country">Country</label>
        <p-dropdown
          id="country"
          [style]="{ width: '66.6667%' }"
          [options]="countries"
          formControlName="country"
          optionLabel="name"
          optionValue="name"
          filterBy="name"
          [showClear]="true"
          placeholder="Select a country"
        ></p-dropdown>
      </div>
      <!--<div class="field" style="display: grid;">
        <label for="privatePrice">Private Price</label>
        <p-dropdown
        id="privatePrice"
        [style]="{ width: '66.6667%' }"
        [options]="prices"
        formControlName="privatePrice"
        optionLabel="name"
        optionValue="name"
        filterBy="name"
        [showClear]="true"
        placeholder="Select private price"
      ></p-dropdown>
      </div>-->
    </div>
    <div class="col-4">
      <div class="field" style="display: grid">
        <label for="email">Email</label>
        <input type="text" class="w-8 h-3rem border-round-md border-1 border-primary-400" pInputText formControlName="email" placeholder="Enter Email" />
        <small *ngIf="userForm?.get('email')?.invalid && (userForm?.get('email')?.dirty || userForm?.get('email')?.touched)" class="block p-error">
          <div *ngIf="userForm?.get('email')?.errors?.['required']">Name is required.</div>
          <div *ngIf="userForm?.get('email')?.errors?.['email']">Email should be valid</div>
        </small>
      </div>
      <div class="field" style="display: grid">
        <label for="userName">User Name</label>
        <input type="text" class="w-8 h-3rem border-round-md border-1 border-primary-400" pInputText formControlName="userName" placeholder="Enter User Name" />
        <small *ngIf="userForm?.get('userName')?.invalid && (userForm?.get('userName')?.dirty || userForm?.get('userName')?.touched)" class="block p-error">
          <div *ngIf="userForm?.get('userName')?.errors?.['required']">Name is required.</div>
        </small>
      </div>
      <div class="field" style="display: grid">
        <label for="passWord">Password</label>
        <p-password
          [toggleMask]="true"
          formControlName="password"
          placeholder="Password"
          promptLabel="Type your password"
          weakLabel="Too simple"
          mediumLabel="Average complexity"
          strongLabel="Complex password"
          [inputStyle]="{ width: '364.438px' }"
        />
        <small *ngIf="userForm?.get('password')?.invalid && (userForm?.get('password')?.dirty || userForm?.get('password')?.touched)" class="block p-error">
          <div *ngIf="userForm?.get('password')?.errors?.['required']">Password is required.</div>
        </small>
      </div>
      <div class="field" style="display: grid">
        <label for="confirmPassword">Confirm Password</label>
        <p-password
          [toggleMask]="true"
          formControlName="confirmPassword"
          placeholder="Confirm Password"
          promptLabel="Type your password"
          weakLabel="Too simple"
          mediumLabel="Average complexity"
          strongLabel="Complex password"
          [inputStyle]="{ width: '364.438px' }"
        />
        <small
          *ngIf="userForm?.get('confirmPassword')?.invalid && (userForm?.get('confirmPassword')?.dirty || userForm?.get('confirmPassword')?.touched)"
          class="block p-error"
        >
          <div *ngIf="userForm?.get('confirmPassword')?.errors?.['required']">Confirm the password</div>
        </small>
        <small
          class="block p-error"
          *ngIf="userForm?.errors?.['passwordMismatch'] && userForm?.get('confirmPassword')?.valid && userForm?.get('password')?.valid"
        >
          Password should match
        </small>
      </div>
    </div>
  </div>
  <div class="flex align-items-stretch flex-wrap" style="min-height: 200px">
    <p-button icon="pi pi-save" label="Save User" severity="success" [disabled]="userForm.invalid" class="p-button-success" (click)="saveUser()" />
  </div>
</form>
<p-toast position="top-center"></p-toast>
