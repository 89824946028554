<form [formGroup]="registerForm" class="justify-content-center" style="display: flex" (ngSubmit)="submitDetails()"
  *ngIf="showForm">
  <p-card header="Create account" class="justify-content-center" [style]="{ width: '800px' }">
    <div class="grid">
      <div class="col">
        <div class="field">
          <label for="firstName">First Name</label>
          <input type="text" class="w-full h-3rem border-round-md border-1 border-primary-400" pInputText
            formControlName="firstName" placeholder="Type your first name" />
          <small *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)" class="block p-error"
            style="margin-left: 3px !important">
            <div *ngIf="firstName.errors?.['required']">Name is required.</div>
            <div *ngIf="firstName.errors?.['pattern']">Enter a valid Name</div>
          </small>
        </div>
        <div class="field">
          <label for="lastName">Last Name</label>
          <input type="text" class="w-full h-3rem border-round-md border-1 border-primary-400" pInputText
            formControlName="lastName" placeholder="Type your last name" />
          <small *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)" class="block p-error"
            style="margin-left: 3px !important">
            <div *ngIf="lastName.errors?.['required']">Name is required.</div>
            <div *ngIf="lastName.errors?.['pattern']">Enter a valid Name</div>
          </small>
        </div>
        <div class="field">
          <label for="email">Email</label>
          <input type="text" pInputText formControlName="email"
            class="w-full h-3rem border-round-md border-1 border-primary-400" name="email"
            placeholder="Type your email" />
          <small *ngIf="email.invalid && (email.dirty || email.touched)" class="block p-error"
            style="margin-left: 3px !important">
            <div *ngIf="email.errors?.['required']">Email is required.</div>
            <div *ngIf="email.errors?.['email']">Email should be valid</div>
          </small>
        </div>
        <div class="field">
          <label for="userName">User Name</label>
          <input type="text" pInputText formControlName="userName"
            class="w-full h-3rem border-round-md border-1 border-primary-400" name="userName"
            placeholder="Type your user name" />
          <small *ngIf="userName.invalid && (userName.dirty || userName.touched)" class="block p-error"
            style="margin-left: 3px !important">
            <div *ngIf="userName.errors?.['required']">UserName is required.</div>
            <div *ngIf="userName.errors?.['invalidUserName']">User should be valid</div>
          </small>
        </div>
        <div class="field">
          <label for="address">Address</label>
          <input type="text" pInputText formControlName="address"
            class="w-full h-3rem border-round-md border-1 border-primary-400" name="address"
            placeholder="Type your address" />
          <!--<small *ngIf="username.invalid && (username.dirty || username.touched)" class="block p-error">
            <div *ngIf="username.errors?.['required']">UserName is required.</div>
            <div *ngIf="username.errors?.['username']">Email should be valid</div>
          </small>-->
        </div>
      </div>
      <div class="col">
        <div class="field">
          <label for="dateOfBirth" class="right-title">Date Of Birth</label>
          <p-calendar formControlName="dateOfBirth" placeholder="Type your date of birth" readonlyInput="true"
            [inputStyle]="{ width: '324.59px' }" [yearNavigator]="true" [maxDate]="maxDate"
            [defaultDate]="defaultDate"></p-calendar>

          <!--<small *ngIf="username.invalid && (username.dirty || username.touched)" class="block p-error">
            <div *ngIf="username.errors?.['required']">UserName is required.</div>
            <div *ngIf="username.errors?.['username']">Email should be valid</div>
          </small>-->
        </div>
        <div class="field">
          <label for="phone" class="right-title">Phone</label>
          <input type="text" pInputText formControlName="phone"
            class="w-full h-3rem border-round-md border-1 border-primary-400" name="phone" placeholder="Type your phone"
            style="width: 324.59px !important; margin-left: 1.5rem;" />
          <small *ngIf="phone.invalid && (phone.dirty || phone.touched)" class="block p-error">
            <div *ngIf="phone.errors?.['invalidPhone']">Phone should be valid</div>
          </small>
        </div>
        <div class="field">
          <label for="password" class="right-title">Password</label>
          <!--<input type="password" placeholder="Type your password" class="w-full h-3rem border-round-md border-1 border-primary-400"
                    formControlName="password" pPassword />-->
          <p-password [toggleMask]="true" formControlName="password" placeholder="Type your password"
            promptLabel="Type your password" [feedback]="false" [inputStyle]="{ width: '324.59px' }" />
          <small *ngIf="password.invalid && (password.dirty || password.touched)" class="block p-error">
            <div *ngIf="password.errors?.['required']">Password is required.</div>
          </small>
          <div style="display: flex;">
            <div [ngClass]="[
              'circle',
              password.valid || !password.errors?.['minLength'] ? 'green-circle' : 'red-circle'
              ]">
              <i [ngClass]="[
              'pi',
              password.valid || !password.errors?.['minLength']  ? 'pi-check' : 'pi-times'
              ]"></i>
            </div>
            <span class="label">Password must be at least 8 characters.</span>
          </div>

          <div style="display: flex;">
            <div [ngClass]="[
            'circle',
            password.valid || !password.errors?.['noUpperCase'] ? 'green-circle' : 'red-circle'
            ]">
              <i [ngClass]="[
            'pi',
            password.valid || !password.errors?.['noUpperCase'] ? 'pi-check' : 'pi-times'
            ]"></i>
            </div>
            <span class="label">At least one uppercase letter required.</span>
          </div>

          <div style="display: flex;">
            <div [ngClass]="[
            'circle',
            password.valid || !password.errors?.['noLowerCase'] ? 'green-circle' : 'red-circle'
            ]">
              <i [ngClass]="[
            'pi',
            password.valid || !password.errors?.['noLowerCase'] ? 'pi-check' : 'pi-times'
            ]"></i>
            </div>
            <span class="label">At least one lowercase letter required.</span>
          </div>

          <div style="display: flex;">
            <div [ngClass]="[
            'circle',
            password.valid || !password.errors?.['noDigit'] ? 'green-circle' : 'red-circle'
            ]">
              <i [ngClass]="[
            'pi',
            password.valid || !password.errors?.['noDigit'] ? 'pi-check' : 'pi-times'
            ]"></i>
            </div>
            <span class="label">At least one number required.</span>
          </div>

          <div style="display: flex;">
            <div [ngClass]="[
            'circle',
            password.valid || !password.errors?.['noSpecialChar'] ? 'green-circle' : 'red-circle'
            ]">
              <i [ngClass]="[
            'pi',
            password.valid || !password.errors?.['noSpecialChar'] ? 'pi-check' : 'pi-times'
            ]"></i>
            </div>
            <span class="label">At least one special character required.</span>
          </div>
        </div>
        <div class="field">
          <label for="confirmPassword" class="right-title">Confirm Password</label>
          <!--<input type="password" pPassword formControlName="confirmPassword"
                    class="w-full h-3rem border-round-md border-1 border-primary-400" name="confirmPassword"
                    placeholder="Confirm Password" />-->
          <p-password [toggleMask]="true" formControlName="confirmPassword" promptLabel="Confirm Password"
            placeholder="Confirm Password" [feedback]="false" [inputStyle]="{ width: '324.59px' }" />
          <small *ngIf="confirmPassword.invalid && (confirmPassword.dirty || confirmPassword.touched)"
            class="block p-error">
            <div *ngIf="confirmPassword.errors?.['required']">Confirm the password</div>
          </small>
          <small class="block p-error"
            *ngIf="registerForm.errors?.['passwordMismatch'] && confirmPassword.valid && password.valid">
            Password should match
          </small>
        </div>
      </div>
    </div>
    <div class="grid">
      <div class="col-4"></div>
      <div class="col-4">
        <p-button label="Create" styleClass="w-full btn-create" [style]="{ 'margin-left': '2rem' }"
          [disabled]="registerForm.invalid" type="submit"></p-button>
      </div>
    </div>
    <div class="grid">
      <div class="col-4"></div>
      <div class="col-4">
        <div style="margin-left: 4rem" class="mt-2">Already a member? <a href="/login">Login</a></div>
      </div>
    </div>
  </p-card>
</form>
<br />
<p-toast position="top-center"></p-toast>