<div class="card">
  <p-card [style]="{ height: '100%' }">
    <ng-template pTemplate="content">
      <div class="scrollable-content">
        <p-fieldset [toggleable]="true"[style]="{ 'margin-bottom': '10px' }" legend="About Us">
          Welcome to <strong>PrintHubPod</strong>, a Houston-based leader in DTF (Direct-to-Film) apparel fulfillment, serving businesses and sellers across the
          globe with a strong focus on the U.S. market. Our cutting-edge printing technology, high-volume production capacity, and efficient warehouse
          management make us the ideal partner for sellers looking to scale their operations and offer top-quality custom apparel to customers worldwide.
        </p-fieldset>

        <p-fieldset [toggleable]="true"[style]="{ 'margin-bottom': '10px' }" legend="Our Mission">
          At <strong>PrintHubPod</strong>, our mission is to provide global sellers with reliable, high-quality fulfillment services tailored to their growing
          needs. Whether you're an e-commerce brand, a clothing line, or an artist, we help you bring your products to market quickly and efficiently, ensuring
          your customers receive premium apparel and outstanding service, no matter where they are.
        </p-fieldset>

        <p-fieldset [toggleable]="true"[style]="{ 'margin-bottom': '10px' }" legend="Houston-Based, Global Reach">
          Though we are proudly based in Houston, Texas, our fulfillment services reach far beyond. We specialize in fulfilling orders for international sellers
          with a particular focus on serving the U.S. market. Our centrally located U.S. fulfillment center allows for fast, cost-effective shipping across the
          United States, while our global network ensures that we can meet the demands of international clients as well.
        </p-fieldset>
        <p-fieldset [toggleable]="true"[style]="{ 'margin-bottom': '10px' }" legend="Why DTF Printing?">
          Our advanced DTF (Direct-to-Film) printing technology ensures vibrant, durable prints that work on a wide range of fabrics. This process allows for
          detailed, full-color designs with superior washability and flexibility, making it the best choice for custom apparel that stands the test of time. No
          matter the order size or the complexity of your designs, our DTF printing ensures top-notch results every time.
        </p-fieldset>

        <p-fieldset [toggleable]="true"[style]="{ 'margin-bottom': '10px' }" legend="High-Volume Production & Global Fulfillment">
          We are experts in high-volume production and global fulfillment. Whether you're shipping to customers in the U.S. or around the world, our
          Houston-based warehouse is equipped to manage large-scale operations, ensuring fast, accurate, and reliable fulfillment of thousands of orders daily.
        </p-fieldset>

        <p-fieldset [toggleable]="true"[style]="{ 'margin-bottom': '10px' }" legend="What We Offer">
          <ul>
            <li>Custom Apparel Solutions: High-quality t-shirts, hoodies, and other apparel, available in various styles, sizes, and colors.</li>
            <li>DTF Printing Excellence: Advanced DTF printing technology for vibrant, durable designs on a range of fabrics.</li>
            <li>High-Volume Fulfillment: Scalable production capacity for both small businesses and large-scale operations.</li>
            <li>US Shipping: Seamless fulfillment for international sellers, with a focus on efficient, fast shipping to the U.S. markets.</li>
            <li>
              Warehouse & Inventory Management: Our state-of-the-art warehouse allows for efficient inventory management, ensuring your stock is ready for
              fulfillment when needed.
            </li>
          </ul>
        </p-fieldset>

        <p-fieldset [toggleable]="true"[style]="{ 'margin-bottom': '10px' }" legend="Our Process">
          <ul>
            <li>Design Upload: Easily upload your designs via our platform and customize your apparel.</li>
            <li>Production: Our team uses industry-leading DTF printing technology to create high-quality garments.</li>
            <li>Quality Control: Every product is carefully inspected to ensure it meets our strict quality standards.</li>
            <li>
              Fulfillment & Global Shipping: From our Houston warehouse, we manage your inventory and ship your orders to customers worldwide, with a focus on
              timely, cost-effective delivery to the U.S.
            </li>
          </ul>
        </p-fieldset>

        <p-fieldset [toggleable]="true"[style]="{ 'margin-bottom': '10px' }" legend="Why Choose Us?">
          <ul>
            <li>
              Global Fulfillment Expertise: We specialize in providing seamless fulfillment services for sellers around the world, ensuring your products reach
              customers across borders.
            </li>
            <li>
              Focus on the U.S. Market: With our Houston-based fulfillment center, we offer fast, affordable shipping throughout the United States, a key market
              for many global sellers.
            </li>
            <li>
              High-Volume Capability: Our warehouse and production facilities are designed to handle large volumes, allowing you to grow your business without
              worrying about fulfillment bottlenecks.
            </li>
            <li>Customized Solutions: We provide personalized branding options and packaging services to help your products stand out.</li>
            <li>
              Eco-Friendly Options: We offer sustainable apparel and eco-friendly printing methods to meet the needs of environmentally conscious customers.
            </li>
          </ul>
        </p-fieldset>

        <p-fieldset [toggleable]="true"[style]="{ 'margin-bottom': '10px' }" legend="Trusted by Global Sellers">
          At <strong>PrintHubPod</strong>, we understand the complexities of running a global business and the importance of reliable, fast fulfillment. We are
          trusted by sellers worldwide to manage high-volume production and shipping, with a focus on efficiently serving the U.S. market. Our goal is to help
          you grow your business while ensuring your customers receive exceptional products on time, every time.

          <br />
          <br />
          Let <strong>PrintHubPod</strong> be your fulfillment partner, helping you reach customers across the globe with ease. Contact us today to get started!
        </p-fieldset>
      </div>
    </ng-template>
  </p-card>
</div>
