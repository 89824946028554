<app-product-toolbar></app-product-toolbar>

<br />
<h3 *ngIf="formMode === 'new'">Create New Product</h3>
<h3 *ngIf="formMode === 'edit'">Edit Product</h3>
<div class="new-product-container">
  <form [formGroup]="mainForm" (ngSubmit)="onSubmit()">
    <div class="p-fluid">
      <div class="p-field" class="text-input">
        <label for="brand">Brand</label>
        <input id="brand" pInputText formControlName="brand" required />
        <small *ngIf="brand?.hasError('required')" class="p-error">Brand is required</small>
        <small *ngIf="brand?.hasError('minlength')" class="p-error">Brand must be at least 3 characters long</small>
      </div>

      <div class="p-field" class="text-input">
        <label for="name">Name</label>
        <input id="name" pInputText formControlName="name" required />
        <small *ngIf="name?.hasError('required')" class="p-error">Name is required</small>
        <small *ngIf="name?.hasError('minlength')" class="p-error">Name must be at least 3 characters long</small>
      </div>

      <div class="p-field" class="text-input mb-2 mt-2">
        <label>Style</label>
        <div *ngFor="let option of styles">
          <p-radioButton formControlName="style" name="style" [value]="option" [label]="option" class="mr-2"></p-radioButton>
        </div>
      </div>

      <div class="p-field" class="text-input">
        <label for="code">Code</label>
        <input id="code" pInputText formControlName="code" required />
        <small *ngIf="code?.hasError('required')" class="p-error">Code is required</small>
      </div>
    </div>

    <br />

    <div class="p-field" class="text-input">
      <label for="sizes">Sizes</label>
      <div>
        <p-multiSelect
          formControlName="sizeSelectedList"
          [options]="sizeList"
          optionLabel="label"
          optionValue="value"
          placeholder="Select Sizes"
          class="p-multiselect mr-2"
        >
        </p-multiSelect>
        <button pButton type="button" severity="success" icon="pi pi-plus" label="Add Size" (click)="addSize()"></button>
      </div>
    </div>
    <div class="p-field" class="text-input">
      <label></label>
      <div class="size-color">
        <div formArrayName="sizes" class="variant" *ngFor="let size of sizes.controls; let i = index; let last = last">
          <div class="variant-label">
            <button pButton type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text" (click)="removeSize(i)"></button>
            <span>{{ size.value.size }}</span>
          </div>
        </div>
      </div>
    </div>
    <br /><br />

    <div class="p-field" class="text-input">
      <label>Colors</label>
      <div>
        <input pInputText formControlName="color" pTooltip="Enter input then click Add Color" tooltipPosition="top" />&nbsp;
        <button pButton type="button" severity="success" icon="pi pi-plus" label="Add Color" (click)="addColor()"></button>
      </div>
    </div>
    <div class="p-field" class="text-input">
      <label></label>
      <div class="size-color">
        <div formArrayName="colors" class="variant" *ngFor="let color of colors.controls; let i = index; let last = last">
          <div class="variant-label">
            <button pButton type="button" icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text" (click)="removeColor(i)"></button>
            <span>{{ color.value.color }}</span>
          </div>
        </div>
      </div>
    </div>
    <br /><br />
    <div class="p-field" class="text-input">
      <label></label>
      <button
        pButton
        type="submit"
        icon="pi pi-save"
        label="Save"
        severity="success"
        class="p-button-raised p-button-primary"
        [disabled]="mainForm.invalid"
        class="mr-3"
      ></button>
      <button
        pButton
        type="submit"
        icon="pi pi-angle-left"
        label="Back to Products"
        class="p-button-raised p-button-primary"
        class="ml-5"
        (click)="navigate('/product/products')"
      ></button>
    </div>
  </form>
</div>
