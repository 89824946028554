<app-product-toolbar></app-product-toolbar>
<br />
<p-toolbar>
  <p-button icon="pi pi-plus" label="New Product" severity="success" class="p-button-success" (click)="navigate('/product/new-product')" />
</p-toolbar>
<p-progressBar *ngIf="isLoading" mode="indeterminate" [style]="{ height: '3px' }" />
<p-card>
  <p-table
    [value]="products"
    [paginator]="true"
    [rows]="20"
    class="p-datatable-sm"
    currentPageReportTemplate="Showing {first} to {last} of {{ products.length }}"
    [showCurrentPageReport]="true"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>#</th>
        <th pSortableColumn="id">ID <p-sortIcon field="id"></p-sortIcon></th>
        <th pSortableColumn="brand">Brand <p-sortIcon field="brand"></p-sortIcon></th>
        <th pSortableColumn="name">Name <p-sortIcon field="name"></p-sortIcon></th>
        <th pSortableColumn="style">Style <p-sortIcon field="style"></p-sortIcon></th>
        <th pSortableColumn="code">Code <p-sortIcon field="product"></p-sortIcon></th>
        <th>Sizes</th>
        <th>Colors</th>
        <th>Actions</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-rowIndex="rowIndex">
      <tr class="data-row">
        <td>{{ rowIndex + 1 }}</td>
        <td>{{ product.id }}</td>
        <td>{{ product.brand }}</td>
        <td>{{ product.name }}</td>
        <td>{{ product.style }}</td>
        <td>{{ product.code }}</td>
        <td>
          <span *ngFor="let size of product.sizes; let last = last"> {{ size.size }}<span *ngIf="!last">, </span> </span>
        </td>
        <td>
          <span *ngFor="let color of product.colors; let last = last"> {{ color.color }}<span *ngIf="!last">, </span> </span>
        </td>
        <td>
          <button
            pButton
            type="button"
            icon="pi pi-pencil"
            pTooltip="Update"
            tooltipPosition="top"
            class="p-button-rounded p-button-success p-button-text"
            (click)="openProductEdit(product)"
          ></button>
          <button
            pButton
            type="button"
            icon="pi pi-trash"
            pTooltip="Delete"
            tooltipPosition="top"
            severity="danger"
            [outlined]="true"
            class="p-button-rounded p-button-danger p-button-text"
            (click)="openDeleteDialog(product)"
          ></button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <p-confirmDialog [style]="{ width: '30vw' }"></p-confirmDialog>
</p-card>
