<div class="card">
  <p-card  [style]="{ height: '100%' }">
    <p-tabView>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
            <i class="pi pi-receipt" style="font-size: 1.5rem"></i>
            <span class="font-bold white-space-nowrap m-0">T-SHIRT</span>
          </div>
        </ng-template>
        <div style="text-align: center">
          <p>
            Sizes:
            <p-tag severity="success" value="S" class="mr-2" />
            <p-tag severity="contrast" value="M" class="mr-2" />
            <p-tag severity="success" value="L" class="mr-2" />
            <p-tag severity="contrast" value="XL" class="mr-2" />
            <p-tag severity="success" value="2XL" class="mr-2" />
            <p-tag severity="contrast" value="3XL" class="mr-2" />
            <p-tag severity="success" value="4XL" /> The quantity and color vary depending on the season.
          </p>
          <br><br>
          <p-image src="../../../assets/images/production/t-shirt-sizes.png" alt="Image" width="45%" class="mr-5" />
          <p-image src="../../../assets/images/production/t-shirt.png" alt="Image" width="50%" />
        </div>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
            <i class="pi pi-receipt" style="font-size: 1.5rem"></i>
            <span class="font-bold white-space-nowrap m-0">SWEATSHIRT</span>
          </div>
        </ng-template>
        <div style="text-align: center">
          <p>
            Sizes:
            <p-tag severity="success" value="S" class="mr-2" />
            <p-tag severity="contrast" value="M" class="mr-2" />
            <p-tag severity="success" value="L" class="mr-2" />
            <p-tag severity="contrast" value="XL" class="mr-2" />
            <p-tag severity="success" value="2XL" class="mr-2" />
            <p-tag severity="contrast" value="3XL" class="mr-2" />  The quantity and color vary depending on the season.
          </p>
          <br><br>
          <p-image src="../../../assets/images/production/sweatshirt-sizes.png" alt="Image" width="45%" class="mr-5" />
          <p-image src="../../../assets/images/production/sweatshirt.png" alt="Image" width="50%" />
        </div>
      </p-tabPanel>
      <p-tabPanel>
        <ng-template pTemplate="header">
          <div class="flex align-items-center gap-2">
            <i class="pi pi-receipt" style="font-size: 1.5rem"></i>
            <span class="font-bold white-space-nowrap m-0">HOODIE</span>
          </div>
        </ng-template>
        <div style="text-align: center">
          <p>
            Sizes:
            <p-tag severity="success" value="S" class="mr-2" />
            <p-tag severity="contrast" value="M" class="mr-2" />
            <p-tag severity="success" value="L" class="mr-2" />
            <p-tag severity="contrast" value="XL" class="mr-2" />
            <p-tag severity="success" value="2XL" class="mr-2" />
            <p-tag severity="contrast" value="3XL" class="mr-2" />  The quantity and color vary depending on the season.
          </p>
          <p-image src="../../../assets/images/production/hoodie-sizes.png" alt="Image" width="45%" class="mr-5" />
          <p-image src="../../../assets/images/production/hoodie.png" alt="Image" width="50%" />
        </div>
      </p-tabPanel>
    </p-tabView>
</p-card>
</div>

